import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { Formik,Form,ErrorMessage } from 'formik'

import { forgotPwdVerify,resetPwd } from '../services/api'
import { queryStringToObj } from '../../links/service/api'

import TextMsg from '../../messages/modules/TextMsg'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props)

        /**MOCKUP
         * {ID:<id_user>,user:<display_name>}
         */
        const user = this.props.hasOwnProperty('user')
            ? this.props.user
            : {}
        this.state = {
            showPassword: false,
            showPassword_repeat: false,
            reset_done: false,
            user: user,
        }
        
        this.resetPwd = this.resetPwd.bind(this)
    }

    async componentDidMount() {
        const queryStrings = queryStringToObj(this.props.location.search)
        const r = queryStrings.hasOwnProperty('r')
            ? queryStrings.r
            : ''

        if( r ) {
            const user = await forgotPwdVerify( r )
            this.setState({
                user: user
            })
        }
    }

    validate( values ) {
        const id = values.hasOwnProperty('id') ? values.id : 0
        const password = values.hasOwnProperty('password') ? values.password : ''
        const password_repeat = values.hasOwnProperty('password_repeat') ? values.password_repeat : ''

        let errors = {}
        if( !id )
            errors['id'] = "Invalid user ID: contact administrator to fix"
        if( !password )
            errors['password'] = "Password cannot be empty"
        if( !password_repeat )
            errors['password_repeat'] = "You must repeat you new password"
        if( password !== password_repeat )
            errors['password_repeat'] = "Password repeat must be the same as password"

        //exec hook onValidate
        const onValidate_f = this.props.hasOwnProperty('onValidate')
            ? this.props.onValidate
            : (values,errors) => {return errors}
        errors = onValidate_f(values,errors)

        return errors
    }
    async resetPwd(data) {
        const result = await resetPwd( data )
        this.setState({
            reset_done: parseInt(result) ? true : false
        })
    }

    renderWelcomeMsg( user ) {
        const msgWelcome_f = this.props.hasOwnProperty('msgWelcome')
            ? this.props.msgWelcome
            : (user) => <React.Fragment>
                Hello {user.user},<br />
                insert yor new password twice, please.
            </React.Fragment>
        return <Typography component={Box} variant="body2">
            {msgWelcome_f(user)}
        </Typography>
    }

    handleClickShowPassword = (repeat) => {
        this.setState((prevState) => ({
            showPassword: repeat === false ? !prevState.showPassword : this.state.showPassword,
            showPassword_repeat: repeat === true ? !prevState.showPassword_repeat : this.state.showPassword_repeat,
        }))
    }

    render() {
        const showPassword = this.state.showPassword
        const showPassword_repeat = this.state.showPassword_repeat
        const user = this.state.user
        const id_user = user.hasOwnProperty('ID') ? user.ID : 0
        const reset_done = this.state.reset_done
        const msgAfterReset = this.props.hasOwnProperty('msgAfterReset')
            ? this.props.msgAfterReset
            : 'Password updated. Go beck to login page'

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps) => <Button color="primary" variant="contained"
                disabled={!formikProps.isValid}
                type="submit"
            >Reset</Button>
        const passwordProps = this.props.hasOwnProperty('passwordProps') ? this.props.passwordProps : {}

        return <React.Fragment>
            {!Object.keys(user).length
                ? <LoaderSingleComponent width="100%" />
                : <React.Fragment>
                    {this.renderWelcomeMsg( user )}
                    <Formik
                        initialValues={{id: id_user,password: '',password_repeat: ''}}
                        validate={(values) => {
                            return this.validate( values )
                        }}
                        onSubmit={(values,actions) => {
                            const errors = this.validate( values )
                            if( !Object.keys(errors).length )
                                this.resetPwd(values)
                        }}
                        render={formikProps => (
                            <React.Fragment>
                                <Form id="resetPwdForm" onSubmit={formikProps.handleSubmit}>
                                    <Box mb={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                label="New password"
                                                error={formikProps.errors.password && formikProps.touched.password ? true : false}
                                                value={formikProps.values.password}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                margin="normal"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => this.handleClickShowPassword(false)}
                                                            edge="end"
                                                            aria-label={showPassword ? 'Nascondi password' : 'Mostra password'}
                                                        >
                                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                {...passwordProps}
                                            />
                                            <ErrorMessage name="password">
                                                {(msg) => (<TextMsg severity="error" message={msg} />)}
                                            </ErrorMessage>
                                            <TextField
                                                id="password-repeat"
                                                type={showPassword_repeat ? 'text' : 'password'}
                                                name="password_repeat"
                                                label="Repeat new password"
                                                error={formikProps.errors.password_repeat && formikProps.touched.password_repeat ? true : false}
                                                value={formikProps.values.password_repeat}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                margin="normal"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => this.handleClickShowPassword(true)}
                                                            edge="end"
                                                            aria-label={showPassword_repeat ? 'Nascondi password' : 'Mostra password'}
                                                        >
                                                            {showPassword_repeat ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                {...passwordProps}
                                            />
                                            <ErrorMessage name="password_repeat">
                                                {(msg) => (<TextMsg severity="error" message={msg} />)}
                                            </ErrorMessage>
                                        </FormControl>
                                    </Box>
                                    {buttons(formikProps)}
                                </Form>
                            </React.Fragment>
                        )}
                    />
            </React.Fragment>}
            <SnackbarMessage
                open={reset_done}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterReset}
                //onClose={() => (window.location.href = redirectTo)}
            />
        </React.Fragment>
    }
}

export default withRouter(ResetPasswordForm)