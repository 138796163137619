import React from 'react'
import { toolbarSchema } from '../../../services/EntityToolbarSchema'

class EntityToolbar extends React.Component {
    constructor(props) {
        super(props)

        this.renderToolbar = this.renderToolbar.bind(this)
    }

    renderToolbar( list ) {
        const entity = this.props.entity

        return <React.Fragment>
            {list.map((btn,b) => (
                <React.Fragment key={entity+'-btn-'+b}>
                    {btn}
                    &nbsp;&nbsp;
                </React.Fragment>
            ))}
        </React.Fragment>
    }

    render() {
        const toolbar = toolbarSchema( this.props )

        return <React.Fragment>
            {this.renderToolbar( toolbar )}
        </React.Fragment>
    }
}

export default EntityToolbar