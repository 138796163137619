import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import Survey from '../modules/Survey'

import { Typography,Box,Paper } from '@material-ui/core'

class SurveyPage extends React.Component {
    render() {
        const title = this.props.route.title
        const s = this.props.route.location.search
        const withBackBtn = s === '?edit' ? true : false

        return <Content title={title} maxWidth="md">
            <Paper><Box p={4}>
            <Typography component={Box} mb={2}>
                {withBackBtn
                ? <React.Fragment>
                    Bentornato!<br />
                    Puoi aggiornare le tue risposte da qui.
                </React.Fragment>
                : <React.Fragment>
                    Nuovo qui?<br />
                    Benvenuto nella community di MeetPods.<br />
                    Dacci qualche suggerimento per aiutarti a trovare il tuo prossimo podcast preferito.
                </React.Fragment>}
            </Typography>
            <Box mt={4}>
                <Survey withBackBtn={withBackBtn} />
            </Box>
            </Box></Paper>
        </Content>
    }
}

export default SurveyPage