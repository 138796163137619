//import LoginPage from '../@react-fefmwk/theme-structure/modules/layouts/LoginPage'
import StandardPage from '../@react-fefmwk/theme-structure/modules/layouts/StandardPage'
import FullWidthPage from '../@react-fefmwk/theme-structure/modules/layouts/FullWidthPage'
import FullWidthNoNavPage from '../@react-fefmwk/theme-structure/modules/layouts/FullWidthNoNavPage'
import EmbedPage from '../@react-fefmwk/theme-structure/modules/layouts/EmbedPage'

import PodsWallLayout from '../theme/layouts/PodsWallLayout'
import SurveyLayout from '../theme/layouts/SurveyLayout'
import LoginLayout from '../theme/layouts/LoginLayout'

import EntityPage from '../@react-fefmwk/entity/modules/pages/EntityPage'
import EntityPageView from '../@react-fefmwk/entity/modules/pages/EntityPageView'
import EntityPageForm from '../@react-fefmwk/entity/modules/pages/EntityPageForm'
//import EntityPageUploadCSV from '../@react-fefmwk/entity/modules/pages/EntityPageUploadCSV'
import EntityPageDelete from '../@react-fefmwk/entity/modules/pages/EntityPageDelete'

import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import VerifyEmail from '../pages/VerifyEmail'
import SignUp from '../pages/SignUp'
import ProfilePage from '../pages/ProfilePage'
import SettingsPage from '../pages/SettingsPage'
import NotificationsPage from '../pages/NotificationsPage'
import PodswallPage from '../pages/PodswallPage'
import SurveyPage from '../pages/SurveyPage'
import SearchPage from '../pages/SearchPage'
import ReviewPage from '../pages/ReviewPage'
import ReviewDeletePage from '../pages/ReviewDeletePage'
import WriteReviewPage from '../pages/WriteReviewPage'
import WritePostPage from '../pages/WritePostPage'
import PostPage from '../pages/PostPage'
import SponsorPodPage from '../pages/SponsorPodPage'
import ShowPage from '../pages/ShowPage'
import AuthorPage from '../pages/AuthorPage'
import PodcasterPage from '../pages/PodcasterPage'
import MyPodsPage from '../pages/MyPodsPage'
import MyFavouritesPage from '../pages/MyFavouritesPage'
import MyPlaylistsPage from '../pages/MyPlaylistsPage'
import PlaylistPage from '../pages/PlaylistPage'
import SponsoredByPage from '../pages/SponsoredByPage'
import AddPlaylistItemPage from '../pages/AddPlaylistItemPage'
import MyCommunityPage from '../pages/MyCommunityPage'

export const AppSchema = {
    "specific": {
        "matchRules": [
            "/",
            "/forgot-password","/reset-password",
            "/verify-email","/verify-email/podcaster",
            "/sign-up","/sign-up/podcaster"
        ],
        "layout": LoginLayout,
        //"layout": LoginPage,
        "layoutProps": {
            "appStyle": {"backgroundImage": "url('/images/mp-login-background.png')"}
        },
        "routes": [
            {
                "slug": "login",
                "visibility": "public",
                "path": "/",
                "title": "Login",
                "component": Login,
            },
            {
                "slug": "forgot-password",
                "visibility": "public",
                "path": "/forgot-password",
                "title": "Ripristina password",
                "component": ForgotPassword,
            },
            {
                "slug": "reset-password",
                "visibility": "public",
                "path": "/reset-password",
                "title": "Reset password",
                "component": ResetPassword,
            },
            {
                "slug": "verify-email",
                "visibility": "public",
                "path": "/verify-email",
                "title": "Verifica la tua email",
                "component": VerifyEmail,
                "props": {
                    "profile": "author"
                }
            },
            {
                "slug": "sign-up",
                "visibility": "public",
                "path": "/sign-up",
                "title": "Iscriviti",
                "component": SignUp,
                "props": {
                    "profile": "author"
                }
            },
            {
                "slug": "verify-email-podcaster",
                "visibility": "public",
                "path": "/verify-email/podcaster",
                "title": "Verifica la tua email",
                "component": VerifyEmail,
                "props": {
                    "profile": "podcaster"
                }
            },
            {
                "slug": "sign-up-podcaster",
                "visibility": "public",
                "path": "/sign-up/podcaster",
                "title": "Iscriviti",
                "component": SignUp,
                "props": {
                    "profile": "podcaster"
                }
            },
        ],
    },
    "pods-wall": {
        "matchRules": ["/app/pods-wall"],
        "layout": PodsWallLayout,
        "routes": [
            {
                "slug": "pods-wall",
                "visibility": "private",
                "path": "/app/pods-wall",
                "title": "",
                "component": PodswallPage,
            },
        ],
    },
    "survey": {
        "matchRules": ["/app/survey"],
        "layout": SurveyLayout,
        "routes": [
            {
                "slug": "survey",
                "visibility": "private",
                "path": "/app/survey",
                "title": "Profilazione",
                "component": SurveyPage,
            },
        ],
    },
    "embed": {
        "matchRules": [
            "/embed/app/survey",
            "/embed/:entity/add","/embed/:entity/update/:id"
        ],
        "layout": EmbedPage,
        "routes": [
            {
                "slug": "embed-survey",
                "visibility": "private",
                "path": "/embed/app/survey",
                "component": SurveyPage,
            },
            {
                "slug": "embed-entity-add",
                "visibility": "private",
                "path": "/embed/:entity/add",
                "component": EntityPageForm,
            },
            {
                "slug": "embed-entity-update-id",
                "visibility": "private",
                "path": "/embed/:entity/update/:id",
                "component": EntityPageForm,
            },
        ],
    },
    "fullwidthpagenonav": {
        "matchRules": [],
        "layout": FullWidthNoNavPage,
        "routes": [],
    },
    "fullwidthpage": {
        "matchRules": [
            "/:entity/add","/:entity/view/:id","/:entity/update/:id","/:entity/delete/:id","/:entity/upload-csv",
        ],
        "layout": FullWidthPage,
        "routes": [
            {
                "slug": "entity-view-id",
                "visibility": "private",
                "path": "/:entity/view/:id",
                "component": EntityPageView,
            },
            {
                "slug": "entity-add",
                "visibility": "private",
                "path": "/:entity/add",
                "component": EntityPageForm,
            },
            /*{
                "slug": "entity-upload-csv",
                "visibility": "private",
                "path": "/:app/:entity/upload-csv",
                "component": EntityPageUploadCSV,
            },*/
            {
                "slug": "entity-update-id",
                "visibility": "private",
                "path": "/:entity/update/:id",
                "component": EntityPageForm,
            },
            {
                "slug": "entity-delete-id",
                "visibility": "private",
                "path": "/:entity/delete/:id",
                "component": EntityPageDelete,
            },],
    },
    "page": {
        "matchRules": [
            "/app/profile",
            "/app/settings",
            "/app/notifications",
            "/app/mypods",
            "/app/mycommunity",
            "/app/myplaylists",
            "/app/myfavourites",
            "/app/mysponsoredby",
            "/app/playlists/:id",
            "/app/playlists/item/add",
            //"/app/playlists/:id/update-item",
            "/app/sponsor/show",
            "/app/post/write","/app/post/outlook/:id",
            "/app/review/write","/app/review/outlook/:id","/app/review/delete/:id",
            "/app/shows/:app/:code",
            "/app/authors/:id",
            "/app/podcasters/:id",
            "/app/:page",
            "/:entity",
        ],
        "layout": StandardPage,
        "routes": [
            {
                "slug": "profile",
                "visibility": "private",
                "path": "/app/profile",
                "title": "Profilo",
                "component": ProfilePage,
            },
            {
                "slug": "settings",
                "visibility": "private",
                "path": "/app/settings",
                "title": "Impostazioni",
                "component": SettingsPage,
            },
            {
                "slug": "notifications",
                "visibility": "private",
                "path": "/app/notifications",
                "title": "Notifiche",
                "component": NotificationsPage,
            },
            {
                "slug": "mycommunity",
                "visibility": "private",
                "path": "/app/mycommunity",
                "title": "My community",
                "component": MyCommunityPage,
            },
            {
                "slug": "search",
                "visibility": "private",
                "path": "/app/search",
                "title": "Scopri",
                "component": SearchPage,
            },
            {
                "slug": "mypods",
                "visibility": "private",
                "path": "/app/mypods",
                "title": "MyPodcasts",
                "component": MyPodsPage,
            },
            {
                "slug": "myfavourites",
                "visibility": "private",
                "path": "/app/myfavourites",
                "title": "La mia lista",
                "component": MyFavouritesPage,
            },
            {
                "slug": "mysponsoredby",
                "visibility": "private",
                "path": "/app/mysponsoredby",
                "title": "Consigliati da un amico",
                "component": SponsoredByPage,
            },
            {
                "slug": "myplaylists",
                "visibility": "private",
                "path": "/app/myplaylists",
                "title": "Tutte le mie playlist",
                "component": MyPlaylistsPage,
            },
            {
                "slug": "playlist-page",
                "visibility": "private",
                "path": "/app/playlists/:id",
                "title": "Playlist",
                "component": PlaylistPage,
            },
            {
                "slug": "playlists-add-item",
                "visibility": "private",
                "path": "/app/playlists/item/add",
                "title": "Aggiungi il Podcast alla playlist",
                "component": AddPlaylistItemPage,
            },
            /*{
                "slug": "playlist-id-add-item-id",
                "visibility": "private",
                "path": "/app/playlists/:id/update-item/:item",
                "title": "UPlaylist",
                "component": PlaylistPage,
            },*/
            {
                "slug": "review-outlook",
                "visibility": "private",
                "path": "/app/review/outlook/:id",
                "title": "Recensione",
                "component": ReviewPage,
            },
            {
                "slug": "review-outlook",
                "visibility": "private",
                "path": "/app/review/delete/:id",
                "title": "Elimina recensione",
                "component": ReviewDeletePage,
            },
            {
                "slug": "reviews-write",
                "visibility": "private",
                "path": "/app/review/write",
                "title": "Scrivi recensione",
                "component": WriteReviewPage,
            },
            {
                "slug": "posts-write",
                "visibility": "private",
                "path": "/app/post/write",
                "title": "Condividi cosa stai ascoltando",
                "component": WritePostPage,
            },
            {
                "slug": "posts-outlook",
                "visibility": "private",
                "path": "/app/post/outlook/:id",
                "title": "Post",
                "component": PostPage,
            },
            {
                "slug": "sponsor-show",
                "visibility": "private",
                "path": "/app/sponsor/show",
                "title": "Consiglia il post a un amico",
                "component": SponsorPodPage,
            },
            {
                "slug": "shows-app-code",
                "visibility": "private",
                "path": "/app/shows/:app/:code",
                "title": "Show",
                "component": ShowPage,
            },
            {
                "slug": "authors-id",
                "visibility": "private",
                "path": "/app/authors/:id",
                "title": "Profilo utente",
                "component": AuthorPage,
            },
            {
                "slug": "podcasters-id",
                "visibility": "private",
                "path": "/app/podcasters/:id",
                "title": "Profilo podcaster",
                "component": PodcasterPage,
            },
            {
                "slug": "entity-page",
                "visibility": "private",
                "path": "/:entity",
                "component": EntityPage,
            },
        ],
    },
}