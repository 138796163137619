import React from "react"

import Table from "./Table"
import LoaderSingleComponent from "../../loader-app/modules/LoaderSingleComponent"
import { rowObjectToRows,niceField } from '../services/api'

class TableOneRowTransposed extends React.Component {
    render() {
        const {rowObject,fieldsProps,...restProps} = this.props

        if( !Object.keys(rowObject).length ) return <LoaderSingleComponent width="100%" />

        const data = rowObjectToRows( rowObject )
        if( !data.length ) return <LoaderSingleComponent width="100%" />

        return <Table
            {...restProps}
            defaultPageSize="100"
            showPaginator={false}
            data={data}
            fieldsProps={{
                0: {
                    Cell: (props) => {
                        const fld = props.row[0]
                        const fldProp = fieldsProps && Object.keys(fieldsProps).length && fieldsProps.hasOwnProperty(fld) && fieldsProps[fld]
                            ? fieldsProps[fld]
                            : {}
                        const show = fldProp.hasOwnProperty('show') ? fldProp.show : true
                        if( !show ) return <span>&nbsp;</span>

                        const nice_name = fldProp.hasOwnProperty('nice_name') ? fldProp.nice_name : niceField(fld)
                        return nice_name
                    },
                },
                1: {
                    Cell: (props) => {
                        const fld = props.row[0]
                        const fldProp = fieldsProps && Object.keys(fieldsProps).length && fieldsProps.hasOwnProperty(fld) && fieldsProps[fld]
                            ? fieldsProps[fld]
                            : {}
                        const show = fldProp.hasOwnProperty('show') ? fldProp.show : true
                        if( !show ) return <span>&nbsp;</span>

                        let val = props.value
                        const Cell = fldProp.hasOwnProperty('Cell') ? fldProp['Cell'] : null
                        let cellProps = {}
                        cellProps['value'] = val
                        if( (Cell === null || Cell === undefined) && typeof(val) === 'object' )
                            val = JSON.stringify(val)

                        return Cell === null
                            ? val
                            : <Cell {...cellProps} />
                    },
                }
            }}
        />
    }
}

export default TableOneRowTransposed