import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { IconButton } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

import EntityToolbar from "./EntityToolbar"
import LoaderSingleComponent from "../../loader-app/modules/LoaderSingleComponent"
import Table from '../../tables/modules/Table'
import FullHeightBoxes from '../../theme-structure/modules/pieces/FullHeightBoxes'

import { selectFieldsFromList } from '../../arrays/api'
import { entity_list } from '../services/api'
import { tableSchema } from '../../../services/EntityTableSchema'
import { actionSchema } from '../../../services/EntityRowActionsSchema'
import { EntitySchema } from '../../../services/EntitySchema'
import { fieldsListSchema } from '../../../services/EntityFieldsSchema'

const styles = (theme) => {
    return {
        wrapperEntityTable: {
            height: "100%"
        },
    }
}

class Entity extends React.Component {
    constructor(props) {
        super(props)

        const entity = this.props.entity
        const entityObj = EntitySchema[entity]
        this.state = {
            entity: entity,
            entityObj: entityObj,
            data:[],
            loading: false,
        }
    }

    async loadEntity() {
        this.setState({
            loading: true
        })

        let newProps = {...this.props}

        const callback = newProps.hasOwnProperty('callback')
            ? newProps.callback
            : entity_list
        const Q = newProps.hasOwnProperty('Q') ? newProps.Q : {}
        const entity = newProps.entity
        const entityObj = EntitySchema[entity]
        const data = newProps.hasOwnProperty('data')
            ? newProps.data
            : await callback( newProps.entity,Q )

        newProps["data"] = data
        newProps["entityObj"] = entityObj
        newProps["refresh"] = false
        newProps["loading"] = false

        this.setState(newProps)
    }

    async componentDidMount() {
        this.loadEntity()
    }

    async componentDidUpdate(prevProps){
        if( prevProps.entity !== this.props.entity
            || prevProps.Q !== this.props.Q
            || prevProps.callback !== this.props.callback
        ) {
            this.setState({refresh:true})
            this.loadEntity()
        }
    }

    render() {
        const loading = this.state.loading
        const entity = this.state.entity
        const entityObj = this.state.entityObj
        if( !entity || !entityObj || !Object.keys(entityObj).length )
            return <Chip icon={<ErrorIcon />} label="Unmanaged entity" variant="outlined" />

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const refresh = this.state.refresh
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : fieldsListSchema(entity)
        let data = this.state.data
        if(fields.length) {
            data = selectFieldsFromList( data,fields )
        }

        const printPaper = this.props.hasOwnProperty('printPaper') ? this.props.printPaper : true
        const WrapComp = printPaper ? Paper : Box
        const printToolbar = this.props.hasOwnProperty('printToolbar') ? this.props.printToolbar : true
        const hookToolbar = this.props.hasOwnProperty('hookToolbar')
            ? this.props.hookToolbar
            : (entity,data,path) => <EntityToolbar entity={entity} path={path} data={data} component={IconButton} />

        const { classes } = this.props

        return <React.Fragment>
            <FullHeightBoxes
                FixedC={null}
                /*FixedC={printToolbar
                    ? <Box mb={3}>
                        {Toolbar}
                    </Box>
                    : null}*/
                ScrollC={!data.length || refresh
                    ? (loading
                        ? <LoaderSingleComponent width="100%"/>
                        : <EntityToolbar entity={entity} path={path} data={[]} component={IconButton} />)
                    : <WrapComp className={classes.wrapperEntityTable}>
                        <Table material dense highlight
                            data={data}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                            CustomToolbar={(props) => {
                                return printToolbar
                                    ? <React.Fragment>
                                        {hookToolbar(entity,props.data,path)}
                                    </React.Fragment>
                                    : null
                            }}
                        />
                    </WrapComp>
                }
            />
        </React.Fragment>
        /*return <React.Fragment>
            {}
            {!data.length || refresh
                ? <LoaderSingleComponent width="100%"/>
                : <WrapComp>
                    <Table material dense highlight
                        data={data}
                        fieldsProps={fieldsProps}
                        rowActions={rowActions}
                        rowActionsField={id_field}
                        showPagination={true}
                        filterable={true}
                        sortable={true}
                    />
                </WrapComp>
            }
        </React.Fragment>*/
    }
}

export default withStyles(styles)(Entity)