export function selectFieldsFromObj( obj,fields = [] ) {
    let fieldsObj = {}
    fields.map((fld) => {
        if( obj.hasOwnProperty(fld) ) fieldsObj[fld] = obj[fld]
        return true
    })
    return fieldsObj
}
export function selectFieldsFromList( list,fields = [] ) {
    let fieldsList = []
    list.map((obj,o) => {
        const fieldsObj = selectFieldsFromObj( obj,fields )
        fieldsList.push(fieldsObj)
        return true
    })
    return fieldsList
}
export function prependObjToList( list,object ) {
    list.map((obj,o) => {
        list[o]= {...object,...obj}
        return true
    })
    return list
}
export function appendObjToList( list,object ) {
    list.map((obj,o) => {
        list[o]= {...obj,...object}
        return true
    })
    return list
}
export function overrideFieldsObj( obj,obj_over ) {
    if( !Object.keys(obj_over).length ) return obj

    for (const property in obj_over) {
        obj[property] = {...obj[property],...obj_over[property]}
    }

    return obj
}
export function hasOwnPath(obj,path) {
    if(path.includes('.')) {
        if(obj.hasOwnProperty(path.split('.')[0]))
            return hasOwnPath(obj[path.split('.')[0]], path.split('.').slice(1).join('.'))
        else
            return false
    }
    else
        return obj.hasOwnProperty(path)
}
export function getObjPath(obj,path,ifNotFound = undefined) {
    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    var stringToPath = function(path) {
        // If the path isn't a string, return it
        if (typeof path !== 'string') return path

        // Create new array
        var output = []

        // Split to an array with dot notation
        path.split('.').forEach(function (item, index) {
            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {
                // Push to the new array
                if (key.length > 0) {
                    output.push(key)
                }
            })
        })

        return output
    }

    // Get the path as an array
    path = stringToPath(path)

    // Cache the current object
    var current = obj

    // For each item in the path, dig into the object
    for (var i = 0; i < path.length; i++) {
        // If the item isn't found, return undefined
        if (!(path[i] in current)) return ifNotFound

        // Otherwise, update the current  value
        current = current[path[i]]
    }

    return current
}
export function kvToDf( kv,fld_k,fld_v ) {
    const values = Object.values(kv)
    const keys = Object.keys(kv)

    let df = []
    keys.map((k,i) => {
        let v = values[i]

        let row = {}
        row[fld_k] = k
        row[fld_v] = v
        df.push(row)
    })

    return df
}