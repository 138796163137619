import React, { useState } from 'react'

import { Grid,Box,Divider } from '@material-ui/core'

const TableToolbar = (props) => {
    const { 
        pages, 
        page, 
        canPrevious, 
        canNext, 
        pageSize,
        pageSizeOptions,
        onPageSizeChange,
        onPageChange, 
        previousText = 'Previous',
        nextText = 'Next',
        pageText = 'Page',
        ofText = 'of',
        title = '',
        toolbarHook = (props) => null,
        showPagination = true,
        showToolbar = true,
        showPaginator = true
    }  = props

    const [pageInput,setPageInput] = useState(page + 1)

    // Funzione per gestire il salto diretto alla pagina
    const handlePageInputChange = (e) => {
        const value = e.target.value
        setPageInput(value)
    }
    const handlePageInputBlur = () => {
        const pageIndex = Math.max(0, Math.min(pages - 1, Number(pageInput) - 1)) // Limita il valore tra 0 e il numero massimo di pagine
        onPageChange(pageIndex) // Salta alla pagina corretta
    }

    return <React.Fragment>
        <Grid container alignItems="center" className="-table-toolbar">
            {!showToolbar
                ? null
                : <React.Fragment>
                    {title
                        ? <Grid item xs>
                            <div className="--title">
                                <Box pl={2}>{title}</Box>
                            </div>
                        </Grid>
                        : null}
                    <Grid item {...{xs: title ? "auto" : true}}>
                        <div className="-toolbar">
                            {toolbarHook(props)}
                        </div>
                    </Grid>
                </React.Fragment>}
            {!showPaginator
                ? null
                : <React.Fragment>
                    {showToolbar ? <Divider orientation="vertical" variant="middle" flexItem /> : null}
                    <Grid item xs={!showToolbar ? true : "auto"} style={{textAlign: "right"}}>
                        <div className="-pagination">
                            <div className="-previous">
                                <button className="-btn" onClick={() => onPageChange(page - 1)} disabled={!canPrevious}>
                                    {previousText}
                                </button>
                            </div>
                            <div className="-center">
                                <span className="-pageInfo">
                                    {pageText}&nbsp;&nbsp;
                                    <div className="-pageJump">
                                        <input aria-label="jump to page"
                                            type="number"
                                            value={page + 1} min={1} max={pages}
                                            onChange={handlePageInputChange}
                                            onBlur={handlePageInputBlur}
                                        />
                                    </div>
                                    &nbsp;&nbsp;{ofText} <span className="-totalPages">{pages}</span>
                                </span>
                                <span className="select-wrap -pageSizeOptions">
                                    <select
                                        value={pageSize}
                                        onChange={(e) => onPageSizeChange(Number(e.target.value))}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {pageSizeOptions.map((size) => (
                                            <option key={size} value={size}>
                                            Show {size}
                                            </option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                            <div className="-next">
                                <button className="-btn" onClick={() => onPageChange(page + 1)} disabled={!canNext}>
                                    {nextText}
                                </button>
                            </div>
                        </div>
                    </Grid>
                </React.Fragment>}
        </Grid>
    </React.Fragment>
}

export default TableToolbar