import React from "react"
import ReactTable from 'react-table-6'
import { withRouter } from "react-router-dom/cjs/react-router-dom"

import { getTableState,cleanResolvedData } from '../services/api'
import { niceField } from '../services/api'

import TableActions from './TableActions'
import TableToolbar from './TableToolbar'
//import ExportCsvButton from './ExportCsvButton'

import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import BlockIcon from '@material-ui/icons/Block'

import {
    encodeBase64,
    //decodeBase64
} from '../../links/service/api'

import './table.css'
import './material-table.css'
import { ChevronLeft,ChevronRight } from "@material-ui/icons"

/*EXAMPLE UASAGE
<Table
material
dense
striped
highligth
data={[]}
hideHeaders
filterable={true}
sortable={true}
defaultSorted={[{id:'<field>',desc:true},{id:'<field>',desc:true}]}
showPagination={true}
fieldsProps={{
    <field>: {
        Header: "Investor",
        sortable: true,
        filterable: true,
        hideFilterPlaceholder: true,
        Filter: ({filter,onChange}) => {})
        width: 'auto',
        maxWidth: 200,
        minWidth: 700,
        Cell: (props) => {return <Component />}
        style: {},
        className: "",
        getProps: (state,rowInfo,column) => {return {style: {},}},
    },
}}
rowActions={[
    {
        action: "<action>",
        header: "",
        icon: Icon,
        field: "<field>",
        link: "/update/:id",
        linktype: "internal"
    },
]}
/>*/
const rowActionsCellWidth = 60
const rowCheckboxCellWidth = 75
class Table extends React.Component {
    constructor(props) {
        super(props)

        this.columns = this.columns.bind(this)
        this.cell = this.cell.bind(this)
        this.rowActions = this.rowActions.bind(this)
        this.getColumnWidth = this.getColumnWidth.bind(this)

        const rowCheckboxInputName = this.props.hasOwnProperty('rowCheckboxInputName')
            ? this.props.rowCheckboxInputName
            : "selectedRows"
        const rowCheckboxField = this.props.hasOwnProperty('rowCheckboxField')
            ? this.props.rowCheckboxField
            : "checkboxes"
        const rowCheckboxFieldValue = this.props.hasOwnProperty('rowCheckboxFieldValue')
            ? this.props.rowCheckboxFieldValue
            : (rowCheckboxField === "checkboxes" ? "" : rowCheckboxField)
        const rowActionsField = this.props.hasOwnProperty('rowActionsField')
            ? this.props.rowActionsField
            : "actions"
        const rowActionsFieldValue = this.props.hasOwnProperty('rowActionsFieldValue')
            ? this.props.rowActionsFieldValue
            : rowActionsField
        const saveFullRow = this.props.hasOwnProperty('saveFullRow')
            ? this.props.saveFullRow
            : false
        const selectedIdsField = "_supportSelected"+rowCheckboxInputName.charAt(0).toUpperCase() + rowCheckboxInputName.slice(1)

        this.tableRef = React.createRef() // Riferimento alla tabella

        this.state = {
            columns: [],
            rowCheckboxInputName: rowCheckboxInputName,
            rowCheckboxField: rowCheckboxField,
            rowCheckboxFieldValue: rowCheckboxFieldValue,
            rowActionsField: rowActionsField,
            rowActionsFieldValue: rowActionsFieldValue,
            selectAll: false,
            saveFullRow: saveFullRow,
            selectedIdsField: selectedIdsField,
        }
    }

    componentDidMount() {
        this.setState({
            columns: this.columns()
        })
    }

    componentDidUpdate(prevProps,prevState) {
        const selectAll = this.state.selectAll
        if( selectAll !== prevState.selectAll ) {
            this.selectAll()
        }

        const data = this.props.data
        if( data !== prevProps.data ) {
            this.setState({
                columns: this.columns()
            })
        }

        const formikProps = this.props.formikProps
        if( formikProps !== prevProps.formikProps ) {
            this.setState({
                columns: this.columns()
            })
        }
    }

    selectAll() {
        const selectAll = this.state.selectAll
        const data = this.props.data
        const rowCheckboxField = this.props.rowCheckboxField
        const formikProps = this.props.formikProps
        const rowCheckboxInputName = this.state.rowCheckboxInputName
        const saveFullRow = this.state.saveFullRow
        const selectedIdsField = this.state.selectedIdsField

        let selected = []
        let selected_ids = []
        data.map((row,r) => {//select all rows
            if( selectAll ) {
                selected.push(saveFullRow ? row : row[rowCheckboxField])
                selected_ids.push(row[rowCheckboxField])
            }
            else {
                formikProps.values[rowCheckboxInputName].splice(formikProps.values[rowCheckboxInputName].indexOf(row[rowCheckboxField]),1)
                formikProps.values[selectedIdsField].splice(formikProps.values[selectedIdsField].indexOf(row[rowCheckboxField]),1)
            }
            return true
        })

        formikProps.setFieldValue(rowCheckboxInputName,selected)
        formikProps.setFieldValue(selectedIdsField,selected_ids)
    }

    rowCheckbox( data,fieldsProps) {
        const rowCheckbox = this.props.rowCheckbox
        const rowCheckboxFieldValue = this.state.rowCheckboxFieldValue
        if( !rowCheckbox || !rowCheckboxFieldValue ) return {data:data,fieldsProps:fieldsProps}

        const formikProps = this.props.formikProps
        const saveFullRow = this.state.saveFullRow
        const rowCheckboxField = this.state.rowCheckboxField
        const rowCheckboxInputName = this.state.rowCheckboxInputName
        if( !Array.isArray(formikProps.values[rowCheckboxInputName]) || !formikProps.values.hasOwnProperty(rowCheckboxInputName) )
            formikProps.values[rowCheckboxInputName] = []

        const selectedIdsField = "_supportSelected"+rowCheckboxInputName.charAt(0).toUpperCase() + rowCheckboxInputName.slice(1)
        if( !Array.isArray(formikProps.values[selectedIdsField]) || !formikProps.values.hasOwnProperty(selectedIdsField) )
            formikProps.values[selectedIdsField] = []

        const selectAll = this.state.selectAll

        let copyData = []//make a copy without reference
        if( rowCheckboxField === "checkboxes" ) {
            let newRow = {}
            data.map((row,r) => {//add col actions to data
                newRow = {}
                newRow[rowCheckboxField] = ""
                newRow = Object.assign(newRow,row)
                copyData.push(newRow)
                return true
            })
        }
        else copyData = [...data]//make a copy without reference

        const copyFieldsProps = Object.assign({},fieldsProps)//make a copy without reference

        copyFieldsProps[rowCheckboxField] = {
            Header: <React.Fragment>
                <Tooltip title="Select all">
                    <Checkbox
                        id={"checkbox-select-all-"+rowCheckboxField}
                        checked={selectAll}
                        onChange={(e) => {
                            this.setState({
                                selectAll: e.target.checked
                            })
                        }}
                    />
                </Tooltip>
            </React.Fragment>,
            className: "TableCheckboxes",
            maxWidth: rowCheckboxCellWidth,
            Cell: (props) => {
                const index = props.index
                const id = "checkbox"+index
                //const name = `${rowCheckboxInputName}.${index}`
                const supportName = `${selectedIdsField}[]`
                const name = `${rowCheckboxInputName}[]`
                const row = props.row
                const value = props.row[rowCheckboxFieldValue]
                const checked = formikProps.values[selectedIdsField].includes(value)

                return <Checkbox
                    id={id}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={() => {
                        if( checked ) {
                            formikProps.values[rowCheckboxInputName].splice(formikProps.values[rowCheckboxInputName].indexOf(value),1)
                            formikProps.setFieldTouched(name,false,false)
                            formikProps.values[selectedIdsField].splice(formikProps.values[selectedIdsField].indexOf(value),1)
                            formikProps.setFieldTouched(supportName,false,false)
                        }
                        else {
                            formikProps.values[rowCheckboxInputName].push(saveFullRow ? row._original : value)
                            formikProps.setFieldTouched(name,true,true)
                            formikProps.values[selectedIdsField].push(value)
                            formikProps.setFieldTouched(supportName,true,true)
                        }
                    }}
                />
            },
            sortable: false,
            filterable: false,
        }

        return {data:copyData,fieldsProps:copyFieldsProps}
    }
    rowActions( data,fieldsProps,rowActions) {
        if( !rowActions || !rowActions.length ) return {data:data,fieldsProps:fieldsProps}

        const rowActionsField = this.state.rowActionsField
        const rowActionsFieldValue = this.state.rowActionsFieldValue

        //check if rowActionsField is in fields
        const fields = Object.keys(data[0])
        const is_in_fields = fields.includes(rowActionsField) ? true : false

        let copyData = []//make a copy without reference
        if( !is_in_fields || rowActionsField === "actions" ) {
            let newRow = {}
            data.map((row,r) => {//add col actions to data
                newRow = {}
                newRow[rowActionsField] = ""
                newRow = Object.assign(newRow,row)
                copyData.push(newRow)
                return true
            })
        }
        else copyData = [...data]//make a copy without reference

        const copyFieldsProps = Object.assign({},fieldsProps)//make a copy without reference

        //get fieldProps from tableSchema if exist
        const rowActionsFieldProps = copyFieldsProps.hasOwnProperty(rowActionsField)
            ? copyFieldsProps[rowActionsField]
            : {
                Header: niceField(rowActionsField),
                nice_name: niceField(rowActionsField),
            }

        //get props from router
        const {history,location,match} = this.props

        //add actions fieldProp to fieldProps
        copyFieldsProps[rowActionsField] = {
            ...rowActionsFieldProps,
            ...{
                maxWidth: rowActionsCellWidth,
                className: "TableActions",
                Cell: (props) => {
                    const rowActionsList = Array.isArray(rowActions)
                        ? rowActions
                        : rowActions({
                            ...props,
                            ...{route: {history:history,location:location,match:match}}
                        })

                    let copyRowActions = []
                    if( rowActionsList.length > 0 ) {
                        rowActionsList.map((rowAction,r) => {
                            let copyRowAction = Object.assign({},rowAction)//make a copy without reference
                            const linkField = rowActionsFieldValue
                            const linkModel = rowAction.link
                            let id = props.row[linkField]

                            //encoded id_field
                            if( copyRowAction.link.includes(':base64(id)') ) {
                                copyRowAction.link = linkModel.replace(':base64(id)',encodeBase64(id))
                            }

                            //simple id_field
                            if( copyRowAction.link.includes(':id') ) {
                                copyRowAction.link = linkModel.replace(':id',id)
                            }

                            //multiple field values
                            if( copyRowAction.link.includes(':field(') ) {
                                const regExp = /\(([^)]+)\)/g;
                                const matches = [...copyRowAction.link.match(regExp)];
                                if( matches.length ) {
                                    matches.map((match) => {//loop over matches
                                        const matchClean = match.replace('(','').replace(')','')
                                        const matchVal = props.row.hasOwnProperty(matchClean) ? props.row[matchClean] : undefined
                                        if( matchVal !== undefined ) {
                                            copyRowAction.link = copyRowAction.link.replace(':field('+matchClean+')',matchVal)
                                        }
                                        return copyRowAction.link
                                    })
                                }
                            }

                            copyRowActions.push(copyRowAction)
                            return true
                        })
                    }
                    else {
                        copyRowActions.push({
                            action: "none",
                            header: "None",
                            icon: BlockIcon,
                            disabled: true,
                        })
                    }

                    const id = props.value
                    return <TableActions id={id} rowActions={copyRowActions} />
                },
            }
        }

        return {data:copyData,fieldsProps:copyFieldsProps}
    }
    columns() {
        let data = this.props.data
        let fieldsProps = !this.props.hasOwnProperty('fieldsProps') ? {} : this.props.fieldsProps
        //let rowActions = !this.props.hasOwnProperty('rowActions') ? [] : this.props.rowActions
        let rowActions = !this.props.hasOwnProperty('rowActions')
            ? () => true
            : this.props.rowActions
        let tableProps = this.rowActions( data,fieldsProps,rowActions )
        tableProps = this.rowCheckbox( tableProps.data,tableProps.fieldsProps )

        const newData = tableProps.data
        const newFieldsProps = tableProps.fieldsProps

        const allFields = Object.keys(newData[0])
        const selectFields = this.props.hasOwnProperty('selectFields') ? this.props.selectFields : []
        const printAllFields = this.props.hasOwnProperty('printAllFields') ? this.props.printAllFields : false
        const fields = selectFields.length
            ? [...(selectFields.filter(fld => allFields.includes(fld))),...(printAllFields ? allFields.filter(fld => !selectFields.includes(fld)) : [])]
            : allFields

        let columns = []
        fields.map((field,f) => {
            const fieldProp = (newFieldsProps && newFieldsProps.hasOwnProperty(field)) ? newFieldsProps[field] : {}
            columns.push(this.cell(field,fieldProp))
            return true
        })

        return columns
    }
    getColumnWidth(data,field,header) {
        const maxWidth = 200
        const minWidth = 10
        const magicSpacing = 10
        let max = 0

        const rowActionsField = this.state.rowActionsField
        if( field === rowActionsField ) return rowActionsCellWidth

        if(!data.length || !Object.keys(data[0]).length) return "auto"

        for (var i = 0; i < data.length; i++) {
            if (data[i] !== undefined && data[i][field] !== null) {
                if (String(data[i][field] || 'null').length > max) {
                    max = String(data[i][field] || 'null').length
                }
            }
        }

        let width = Math.max(Math.max(max,String(header).length),minWidth)
        const finalWidth = Math.min(maxWidth,width*magicSpacing)

        return finalWidth
    }
    cell( field,fieldProp ) {
        const filterable = this.props.hasOwnProperty('filterable')
            ? this.props.filterable
            : (fieldProp.hasOwnProperty('filterable') ? fieldProp.filterable : true )

        const sortable = this.props.hasOwnProperty('sortable')
            ? this.props.sortable
            : (fieldProp.hasOwnProperty('sortable') ? fieldProp.sortable : true )

        const searchTxt = this.props.hasOwnProperty('searchTxt') ? this.props.searchTxt : 'search...'
        const hideColumnsNames = this.props.hasOwnProperty('hideColumnsNames') ? true : false
        const header = fieldProp.hasOwnProperty('Header') ? fieldProp.Header : niceField(field)
        const dense = this.props.hasOwnProperty('dense') ? true : false
        let cellProps = {
            Header: () => hideColumnsNames
                ? ""
                : (!header ? header : <Box pl={2} pr={2} pt={dense ? 1 : 2} pb={dense ? 1 : 2}>
                    {header}
                </Box>),
            show: fieldProp.hasOwnProperty('show') ? fieldProp.show : true,
            accessor: field,
            sortable: sortable,
            filterable: filterable,
            Filter: fieldProp.hasOwnProperty('Filter')
                ? fieldProp.Filter
                : ({filter,onChange}) => <React.Fragment>
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        placeholder={fieldProp.hideFilterPlaceholder ? "" : searchTxt}
                    />
                    </React.Fragment>,
            Cell: fieldProp.hasOwnProperty('Cell')
                ? fieldProp.Cell
                : (props) => props.value,
        }
        if( fieldProp.hasOwnProperty('className') ) cellProps.className = fieldProp.className
        if( fieldProp.hasOwnProperty('style') ) cellProps.style = fieldProp.style
        if( fieldProp.hasOwnProperty('getProps') ) cellProps.getProps = fieldProp.getProps

        const calcCellWidth = this.props.hasOwnProperty('calcCellWidth')
            ? this.props.calcCellWidth
            : false
        if( calcCellWidth ) {
            const data = this.props.data
            cellProps.width = this.getColumnWidth(data,field,header)
        }
        else {
            if( fieldProp.hasOwnProperty('width') ) cellProps.width = fieldProp.width
            if( fieldProp.hasOwnProperty('maxWidth') ) cellProps.maxWidth = fieldProp.maxWidth
            if( fieldProp.hasOwnProperty('minWidth') ) cellProps.minWidth = fieldProp.minWidth
        }

        return cellProps
    }
    getResolvedData() {
        
    }

    render() {
        const columns = this.state.columns
        if( !columns.length ) return null

        const data = this.props.data
        const datalength = data.length
        if( !datalength ) return null

        const hideHeaders = this.props.hasOwnProperty('hideHeaders') ? {TheadComponent: () => null} : {}
        let showPagination = this.props.hasOwnProperty('showPagination') ? this.props.showPagination : false
        let defaultPageSize = this.props.hasOwnProperty('defaultPageSize')
            ? this.props.defaultPageSize
            : 10
        if( datalength < defaultPageSize ) {
            defaultPageSize = datalength
            //showPagination = false
        }
        const pageSizeOptions = this.props.hasOwnProperty('pageSizeOptions')
            ? this.props.pageSizeOptions
            : [5, 10, 15, 20, 25, 50, 100]

        const material = this.props.hasOwnProperty('material') ? true : false
        const dense = this.props.hasOwnProperty('dense') ? true : false
        let className = ""
        className += !material
            ? ""
            : ("MuiTable-root" + (dense ? " dense" : ""))
        className += this.props.hasOwnProperty('striped') ? ' -striped' : ''
        className += this.props.hasOwnProperty('highlight') ? ' -highlight' : ''
        className += this.props.hasOwnProperty('nopaddingh') ? ' -nopaddingh' : ''

        const defaultFilterMethod = this.props.defaultFilterMethod
            ? this.props.defaultFilterMethod
            : (filter,row) => {
                const filterValue = filter.value.toLowerCase()
                const strToFilter = String(row[filter.id]).toLowerCase()
                return strToFilter.includes(filterValue)
            }

        const defaultSorted = this.props.defaultSorted
            ? this.props.defaultSorted
            : []

        const title = this.props.hasOwnProperty('title') ? this.props.title : ""
        const CustomToolbar = this.props.hasOwnProperty('CustomToolbar')
            ? this.props.CustomToolbar
            : null
        const showToolbar = this.props.hasOwnProperty('showToolbar')
            ? this.props.showToolbar
            : (CustomToolbar !== null ? true : false)
        const showPaginator = this.props.hasOwnProperty('showPaginator')
            ? this.props.showPaginator
            : ((!showToolbar && showPagination) ? true : showPagination)
        if( showToolbar === true || showPaginator === true )
            showPagination = true
        if( !showToolbar && !showPaginator )
            showPagination = false
        const paginatorPosition = this.props.hasOwnProperty('paginatorPosition')
            ? this.props.paginatorPosition
            : 'top'

        return <ReactTable
            ref={this.tableRef} // Associa la tabella al riferimento
            className={className}
            data={data}
            {...hideHeaders}
            columns={columns}
            pageSizeOptions={pageSizeOptions}
            defaultPageSize={defaultPageSize}
            showPagination={showPagination}
            defaultFilterMethod={defaultFilterMethod}
            defaultSorted={defaultSorted}
            showPaginationTop={paginatorPosition === 'top' ? true : false}
            showPaginationBottom={paginatorPosition === 'bottom' ? true : false}
            PaginationComponent={(props) => {
                return <TableToolbar {...props}
                    title={title}
                    nextText={<ChevronRight color="secondary" />}
                    previousText={<ChevronLeft color="secondary" />}
                    showToolbar={showToolbar}
                    showPaginator={showPaginator}
                    toolbarHook={() => {
                        let __data = data
                        const resolvedData = getTableState(this.tableRef)
                        if(Object.keys(resolvedData).length) {
                            __data = cleanResolvedData(resolvedData.sortedData || resolvedData.data)
                        }
                        return CustomToolbar !== null
                            ? <CustomToolbar {...this.props} tableRef={this.tableRef} data={__data} _original={data} />
                            : null
                    }}
                />}}
        />
    }
}

export default withRouter(Table)