import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Table from '../../../tables/modules/Table'
import TimeinfoToolbar from "./TimeinfoToolbar"
import LoaderSingleComponent from '../../../loader-app/modules/LoaderSingleComponent'

import { entity_get_timeinfo } from '../../services/api'
import { tableTimeinfoSchema } from '../../../../services/EntityTimeinfoTableSchema'
import { actionTimeinfoSchema } from '../../../../services/EntityTimeinfoRowActionsSchema'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { Paper,Box } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

const styles = (theme) => {
    return {
        wrapperEntityTable: {
            height: "100%"
        },
    }
}

class ViewTimeinfoEntity extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            data: []
        }
    }

    async componentDidMount() {
        this.setState({
            loading: true
        })

        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const cf = this.props.cf
        const data = await entity_get_timeinfo( entity,id_entity,cf )
        this.setState({
            data: data,
            loading: false
        })
    }

    render() {
        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const cf = this.props.cf
        const cf_nice_name = this.props.cf_nice_name ? this.props.cf_nice_name : this.props.cf
        const cf_name = this.props.cf_name
        const cf_id = this.props.cf_id
        const data = this.state.data
        const loading = this.state.loading

        const rowActions = actionTimeinfoSchema(entity,cf,id_entity)
        const fieldsProps = tableTimeinfoSchema(entity,cf)

        const printToolbar = this.props.hasOwnProperty('printToolbar') ? this.props.printToolbar : true
        const printPaper = this.props.hasOwnProperty('printPaper') ? this.props.printPaper : true
        const WrapComp = printPaper ? Paper : Box

        const title = this.props.title
            ? <Typography variant="overline" component="span" style={{lineHeight: 1}}>
                {this.props.title}
            </Typography>
            : null

        const { classes } = this.props

        return <React.Fragment>
            {data.length
                ? <WrapComp className={classes.wrapperEntityTable}>
                    <Table material dense highlight
                        data={data}
                        fieldsProps={fieldsProps}
                        rowActions={rowActions}
                        rowActionsField={"id"}
                        showPagination={true}
                        filterable={true}
                        sortable={true}
                        title={title}
                        CustomToolbar={(props) => {
                            return printToolbar
                                ? <TimeinfoToolbar
                                        entity={entity}
                                        id_entity={id_entity}
                                        cftime={cf}
                                        data={props.data}
                                        s={"?"+cf_name+"="+cf_id}
                                    />
                                : null
                        }}
                    />
                </WrapComp>
                : <Box p={2}>
                    {loading
                        ? <LoaderSingleComponent width="100%" />
                        : <React.Fragment>
                            <Chip icon={<ErrorIcon />} label={"No "+cf_nice_name+" found"} variant="outlined" />
                            <TimeinfoToolbar
                                entity={entity}
                                id_entity={id_entity}
                                cftime={cf}
                                data={[]}
                                s={"?"+cf_name+"="+cf_id}
                            />
                        </React.Fragment>}
                </Box>}
        </React.Fragment>
    }
}

export default withStyles(styles)(ViewTimeinfoEntity)