import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import {setToken,getToken} from '../@react-fefmwk/auth/services/token'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import DividerAdvanced from '../@react-fefmwk/pieces/modules/DividerAdvanced'

import {
    login_spotify_authlink,
    spotifyLocalTokenKey,
    spotify_login
} from '../services/BE'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LoginForm from '../@react-fefmwk/auth/modules/LoginForm'
import Button from '@material-ui/core/Button'

const loginStyles = {
    cardContent: {
        textAlign:'center'
    },
    loginLogoContainer: {
        width: '100%',
        marginBottom: 5,
        textAlign: 'center',
    },
    loginBtn: {
        width: '100%',
        marginBottom: 5
    },
    linkClean: {
        textDecoration: 'none',
        color: 'gray',
    },
}

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: '',
            spotify_authlink: ''
        }
    }

    async componentDidMount() {
        const queryStrings = queryStringToObj(this.props.route.location.search)
        const code = queryStrings.hasOwnProperty('code')
            ? queryStrings.code
            : ''

        if( code ) {
            //set token to local storage
            setToken( code,spotifyLocalTokenKey )

            //get spotify profile
            const token = await spotify_login(code)
            if( token ) {
                setToken( token )
                this.setState({
                    token: token
                })
            }
        }

        this.setState({
            spotify_authlink: await login_spotify_authlink(),
            code: code,
        })
    }

    loginSpotify() {
        const spotify_authlink = this.state.spotify_authlink
        window.open(spotify_authlink,'_self')
    }

    render() {
        const token = this.state.token
        const theme = this.props.theme
        const defaultHomeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : "/"
        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : defaultHomeUrl
        const msgLoginOk = "Login corretto"
        const msgAfterLogin = this.props.hasOwnProperty('msgAfterLogin')
            ? this.props.msgAfterLogin
            : msgLoginOk

        const spotify_authlink = this.state.spotify_authlink

        const { classes } = this.props
        const inputProps = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            }
        }

        return <React.Fragment>
            <Box component="div" className={classes.loginLogoContainer}>
                <img src="/images/logo-login.png" alt="Login logo" style={{maxWidth:'50%'}} />
            </Box>
            <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                    <LoginForm redirectTo={redirectTo}
                        usernameProps={inputProps}
                        passwordProps={inputProps}
                        msgAfterLogin={msgLoginOk}
                        buttons={(formikProps) => <React.Fragment>
                            <Box mb={4}>
                                <Button color="primary" variant="contained" className={classes.loginBtn}
                                    type="submit"
                                >Accedi</Button>
                                <Button variant="text"  className={classes.loginBtn}
                                    component={AdapterLink}
                                    to="/forgot-password" 
                                >Password dimenticata?</Button>
                            </Box>
                            <DividerAdvanced align="center" spacing={2}>oppure</DividerAdvanced>
                            <Box mt={4}>
                                {spotify_authlink
                                    ? <Button color="secondary" variant="contained" className={classes.loginBtn}
                                        type="button" onClick={() => this.loginSpotify()}
                                    >Continua con spotify</Button>
                                    : <LoaderSingleComponent width="100%" color="secondary" />}
                                <Button variant="text" className={classes.loginBtn}
                                    component={AdapterLink}
                                    to="/verify-email" 
                                >Iscriviti ora</Button>
                                <Button variant="text" className={classes.loginBtn}
                                    component={AdapterLink}
                                    to="/verify-email/podcaster" 
                                >Sei un Podcaster? Clicca qui</Button>
                            </Box>
                        </React.Fragment>}
                    />
                </CardContent>
            </Card>
            {/*Msg login with Spotify*/}
            <SnackbarMessage
                open={token ? true : false}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterLogin}
                onClose={() => (window.location.href = redirectTo)}
            />
        </React.Fragment>
    }
}

export default withStyles(loginStyles)(withTheme(Login))