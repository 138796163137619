import React from 'react'

import Search from './Search'
import SponsoredPodsCarousel from './SponsoredPodsCarousel'
import MyPodsFavourites from './MyPodsFavourites'
import MyPlaylists from './MyPlaylists'

import Box from '@material-ui/core/Box'

class MyPods extends React.Component {
    render() {
        return <React.Fragment>
            <Box mb={4}>
                <Search app="spotify" podPreviewScope="search" placeholder="Cerca show..." />
            </Box>
            <Box mb={4}>
                <SponsoredPodsCarousel />
            </Box>
            <Box mb={4}>
                <MyPodsFavourites title="La mia lista" withStats={false} n={5} />
            </Box>
            <Box mb={4}>
                <MyPlaylists title="Playlist" withStats={false} n={12} />
            </Box>
        </React.Fragment>
    }
}

export default MyPods