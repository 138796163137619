import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
//import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {users_get_mypods} from '../services/BE'
import {podPreviewActionsSchema} from '../services/PodPreviewActionsSchema'

import WidgetPodsList from './widgets/WidgetPodsList'
//import WidgetPodsCarousel from './widgets/WidgetPodsCarousel'

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = (theme) => ({
    LinkToAllFavourites: {
        textDecoration: 'none',
        color: 'initial'
    }
})

class MyPodsFavourites extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mypods: [],
        }
    }
    async getMyPods() {
        const offset = this.props.hasOwnProperty('offset') ? this.props.offset : 0
        const n = this.props.hasOwnProperty('n') ? this.props.n : 100
        const id_user = this.props.hasOwnProperty('id_user')
            ? this.props.id_user
            : idFromToken()

        this.setState({
            mypods: await users_get_mypods( id_user,offset,n )
        })
    }
    async componentDidMount() {
        await this.getMyPods()
    }

    render() {
        const mypods = this.state.mypods

        const withToAllFavourites = this.props.hasOwnProperty('withToAllFavourites') ? this.props.withToAllFavourites : false
        const LinkToAllFavouritesComp = withToAllFavourites ? AdapterLink : Box
        const withTitle = this.props.hasOwnProperty('withTitle') ? this.props.withTitle : true
        const withToolbar = this.props.hasOwnProperty('withToolbar') ? this.props.withToolbar : true
        const withStats = this.props.hasOwnProperty('withStats') ? this.props.withStats : false
        const title = this.props.hasOwnProperty('title') ? this.props.title : 'La mia lista'
        //const height = this.props.hasOwnProperty('height') ? this.props.height : 300

        const classes = this.props.classes

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('mypods')

        return <React.Fragment>
            <Grid container spacing={2} alignItems="center">
                {withTitle
                    ? <Grid item xs="auto">
                        <LinkToAllFavouritesComp to="/app/myfavourites" className={classes.LinkToAllFavourites}>
                            <Tooltip title="La mia lista completa" placement="bottom">
                                <Typography component="h5" variant="h5">{title}</Typography>
                            </Tooltip>
                        </LinkToAllFavouritesComp>
                    </Grid>
                    : null}
                {withToolbar
                    ? <Grid item xs="auto">
                        {withTitle
                            ? <React.Fragment>
                                <Tooltip title="La mia lista completa" placement="top">
                                    <Button color="secondary" variant="outlined" size="small"
                                        aria-label="La mia lista completa"
                                        component={AdapterLink} to="/app/myfavourites"
                                        startIcon={<ChevronRightIcon />}>
                                        Tutti
                                    </Button>
                                </Tooltip>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                    </Grid>
                    : null}
                {withStats
                    ? <Grid item xs="auto">
                        <Chip label={"# totale la mia lista: "+mypods.length} color="secondary" />
                    </Grid>
                    : null}
            </Grid>
            <WidgetPodsList
                pods={mypods}
                boxProps={{pt:1}}
                listProps={{p:0}}
                listItemProps={podPreviewProps}
                onEmptyMsg="Nessuno show nella mia lista"
            />
            {/*<Box style={{height:height}}>
                {!mypods.length
                    ? <LoaderSingleComponent width="100%" />
                    : <WidgetPodsCarousel pods={mypods} />}
            </Box>*/}
        </React.Fragment>
    }
}

export default withStyles(styles)(MyPodsFavourites)