import React from 'react'
import { withStyles } from '@material-ui/core/styles'

//import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {
    authors_get_obj,
    spotify_artist,
    //spotify_artist_get_shows,
    podcaster_get_rss
} from '../services/BE'

import WidgetAuthorPreview from './widgets/WidgetAuthorPreview'
import SpotifyArtist from './spotify/SpotifyArtist'
import SpotifyPodPreview from './spotify/SpotifyPodPreview'
import WidgetRssPreview from './widgets/WidgetRssPreview'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
//import Grid from '@material-ui/core/Grid'
//import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
//import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
//import ListItem from '@material-ui/core/ListItem'
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
//import ListItemText from '@material-ui/core/ListItemText'
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

//import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const style = theme => {
    return {
        linkNoDecoration: {textDecoration:'none'},
    }
}

class Podcaster extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_podcaster: this.props.id_podcaster,
            podcaster: {},
            artist: {},
            artist_rss: {},
        }
    }
    async componentDidMount() {
        const id_podcaster = this.state.id_podcaster
        const podcaster = await authors_get_obj( id_podcaster )
        this.setState({
            podcaster: podcaster,
        })

        const author_nice = podcaster.hasOwnProperty('author_nice') ? podcaster.author_nice : ""
        const artist = author_nice
            ? await spotify_artist(author_nice)
            : {}
        this.setState({
            artist: artist,
        })

        const artist_rss = author_nice
            //? await spotify_artist_get_shows(author_nice)
            ? await podcaster_get_rss(id_podcaster,{items:false})
            : {}
        this.setState({
            artist_rss: artist_rss,
        })
    }

    render() {
        //const id_podcaster = this.state.id_podcaster
        const podcaster = this.state.podcaster
        if( !Object.keys(podcaster).length )
            return <LoaderSingleComponent width="100%" />

        const artist = this.state.artist
        const artist_rss = this.state.artist_rss
        //const {classes} = this.props

        return <React.Fragment>
            <Paper elevation={0}>
                <Box p={2}>
                    <WidgetAuthorPreview
                        radius={60}
                        fontSize="180%"
                        author={podcaster}
                        titleVariant="h6"
                        withActions={true}
                    />
                </Box>
            </Paper>
            <Box mt={2} mb={4}>
                <Typography component={Box} p={2} variant="subtitle1" color="primary">
                    Feed RSS
                </Typography>
                <Box>
                    <WidgetRssPreview rss={artist_rss} />
                </Box>
            </Box>
        </React.Fragment>
    }
}

export default withStyles(style)(Podcaster)