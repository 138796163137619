import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from "react-router-dom"

import '../theme.css'

const EmbedPageStyle = theme => ({
    app: {
        height: '100vh',
        background: "",
        backgroundImage: "",
    },
})

class EmbedPage extends React.Component {
    render() {
        const { classes } = this.props

        const appStyle = this.props.hasOwnProperty('appStyle')
            ? this.props.appStyle
            : {}

        return <div id="App" className={classes.app} style={appStyle}>
            {this.props.children}
        </div>
    }
}

export default withRouter(withStyles(EmbedPageStyle)(EmbedPage))