import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

const styles = (theme) => {
    return {
        boxContainer: {
            height: "100%"
        },
        flexContainer: {
            display:"flex",
            flexDirection: "column",
            height:"100%"
        },
        flexitemScroll: {
            flex: "1 auto",
            height: 0,
            overflowY:"auto",
            overflowX:"hidden",
        },
    }
}

class FullHeightBoxes extends React.Component {
    render() {
        const FixedC = this.props.hasOwnProperty('FixedC')
            ? this.props.FixedC
            : null
        const ScrollC = this.props.hasOwnProperty('ScrollC')
            ? this.props.ScrollC
            : null

        const {classes} = this.props

        return <React.Fragment>
            <Box className={classes.boxContainer}>
                <Box className={classes.flexContainer}>
                    <React.Fragment>
                        {FixedC}
                    </React.Fragment>
                    <Box className={classes.flexitemScroll}>
                        <Box className="FullHeightFlexBox">
                            {ScrollC}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    }
}

export default withStyles(styles)(FullHeightBoxes)