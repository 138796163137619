import React from 'react'
import AppRoutes from './@react-fefmwk/app/modules/AppRoutes'

class App extends React.Component {
    render() {
        return <React.Fragment>
            <AppRoutes />
        </React.Fragment>
    }
}

export default App