import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { Formik,Form,ErrorMessage } from 'formik'

import { verifyEmailVerify,signUp } from '../services/api'
import { queryStringToObj } from '../../links/service/api'

import TextMsg from '../../messages/modules/TextMsg'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

class SignUpForm extends React.Component {
    constructor(props) {
        super(props)

        /**MOCKUP
         * {email:<email>,username:<username>}
         */
        const user = this.props.hasOwnProperty('user')
            ? this.props.user
            : {}
        this.state = {
            serv_terms: false,
            from_emailverify: false,
            signup: false,
            user: user,
            showPassword: false,
        }
        
        this.signUp = this.signUp.bind(this)
    }

    async componentDidMount() {
        const queryStrings = queryStringToObj(this.props.location.search)
        const r = queryStrings.hasOwnProperty('r')
            ? queryStrings.r
            : ''

        if( r ) {
            const user = await verifyEmailVerify( r )
            this.setState({
                from_emailverify: r ? true : false,
                user: user
            })
        }
    }

    validate( values ) {
        const useremail = values.hasOwnProperty('useremail') ? values.useremail : ''
        const display_name = values.hasOwnProperty('display_name') ? values.display_name : ''
        const username = values.hasOwnProperty('username') ? values.username : ''
        const password = values.hasOwnProperty('password') ? values.password : ''
        const password_repeat = values.hasOwnProperty('password_repeat') ? values.password_repeat : ''

        let errors = {}
        if( !useremail )
            errors['useremail'] = "User email name cannot be empty"
        if( !display_name )
            errors['display_name'] = "Display name cannot be empty"
        if( !username )
            errors['username'] = "User name cannot be empty"
        if( !password )
            errors['password'] = "Password cannot be empty"
        if( !password_repeat )
            errors['password_repeat'] = "You must repeat you new password"
        if( password !== password_repeat )
            errors['password_repeat'] = "Password repeat must be the same as password"
        if( password !== password_repeat )
            errors['password_repeat'] = "Password repeat must be the same as password"

        errors = {
            ...errors,
            ...this.validateCustomFields(values)
        }

        return errors
    }
    async signUp(data) {
        const id_user_group = this.props.hasOwnProperty('id_user_group')
            ? this.props.id_user_group
            : 0
        const result = await signUp( data,id_user_group )
        const affected_rows = result.hasOwnProperty('affected_rows')
            ? result.affected_rows
            : 0
        const last_id = result.hasOwnProperty('last_id')
            ? result.last_id
            : 0
        const signup = (parseInt(affected_rows) && parseInt(last_id))
            ? true
            : false
        this.setState({
            signup: signup
        })
    }

    handleChange(e) {
        e.persist()

        this.setState({
            serv_terms: e.target.checked,
        })
    }

    renderWelcomeMsg( user ) {
        const msgWelcome_f = this.props.hasOwnProperty('msgWelcome')
            ? this.props.msgWelcome
            : (user) => <React.Fragment>
                Email {user.email} is verified!<br />
                Fill the form below to create your new account.
            </React.Fragment>
        return <Typography component={Box} variant="body2">
            {msgWelcome_f(user)}
        </Typography>
    }

    /**
     * This method allows developers to add custom fields to the sign up form.
     * See at ./LoginForm.js, method renderCustomFields().
     */
    renderCustomFields( formikProps ) {
        const customFields = this.props.hasOwnProperty('customFields') ? this.props.customFields : []
        if(!customFields.length) return null

        return customFields.map((fieldObj) => {
            const field = fieldObj.hasOwnProperty('name') ? fieldObj.name : ''
            if( !field )
                return null

            return fieldObj.hasOwnProperty('render')
                ? <React.Fragment key={field}>
                    {fieldObj.render(formikProps)}
                    <ErrorMessage name={field}>
                        {(msg) => (<TextMsg severity="error" message={msg} />)}
                    </ErrorMessage>
                </React.Fragment>
                : () => null
        })
    }
    validateCustomFields( values ) {
        let errors = {}
        const customFields = this.props.hasOwnProperty('customFields') ? this.props.customFields : []
        if(!customFields.length)
            return errors

        customFields.map((fieldObj) => {
            const field = fieldObj.hasOwnProperty('name') ? fieldObj.name : ''
            if( !field )
                return null

            const error = fieldObj.hasOwnProperty('validate')
                ? fieldObj.validate(values)
                : ''
            if(error)
                errors[field] = error
        })

        return errors
    }
    initCustomFields() {
        let initialValues = {}
        const customFields = this.props.hasOwnProperty('customFields') ? this.props.customFields : []
        if(!customFields.length)
            return initialValues

        customFields.map((fieldObj) => {
            const field = fieldObj.hasOwnProperty('name') ? fieldObj.name : ''
            if( !field )
                return null

            initialValues[field] = fieldObj.hasOwnProperty('initialValue')
                ? fieldObj.initialValue
                : ''
        })

        return initialValues
    }

    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }))
    }

    render() {
        const showPassword = this.state.showPassword
        const user = this.state.user
        const serv_terms = this.state.serv_terms
        const from_emailverify = this.state.from_emailverify
        const email = user.hasOwnProperty('email') ? user.email : ""
        const signup = this.state.signup
        const msgAfterSignUp = this.props.hasOwnProperty('msgAfterSignUp')
            ? this.props.msgAfterSignUp
            : 'Your account is created!'
        const serviceTerms = this.props.hasOwnProperty('serviceTerms')
            ? this.props.serviceTerms
            : false
        const serviceTermsLabel = this.props.hasOwnProperty('serviceTermsLabel')
            ? this.props.serviceTermsLabel
            : ''

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps,serv_terms) => <Button color="primary" variant="contained"
                type="submit"
                disabled={!formikProps.isValid || !serv_terms}
            >Sign up</Button>
        const useremailProps = this.props.hasOwnProperty('useremailProps') ? this.props.useremailProps : {}
        const displaynameProps = this.props.hasOwnProperty('displaynameProps') ? this.props.displaynameProps : {}
        const usernameProps = this.props.hasOwnProperty('usernameProps') ? this.props.usernameProps : {}
        const passwordProps = this.props.hasOwnProperty('passwordProps') ? this.props.passwordProps : {}

        return <React.Fragment>
            {!Object.keys(user).length
                ? <LoaderSingleComponent width="100%" />
                : <React.Fragment>
                    {this.renderWelcomeMsg( user )}
                    <Formik
                        initialValues={{
                            ...{useremail: email,display_name: '',username: '',password: '',password_repeat: '',serv_terms: false},
                            ...this.initCustomFields()
                        }}
                        validate={(values) => {
                            return this.validate(values)
                        }}
                        onSubmit={(values,actions) => {
                            this.signUp(values)
                        }}
                        render={formikProps => {
                            return <React.Fragment>
                                <Form id="signUpForm" onSubmit={formikProps.handleSubmit}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="useremail"
                                            type="email"
                                            name="useremail"
                                            label="Email"
                                            error={formikProps.errors.useremail && formikProps.touched.useremail ? true : false}
                                            value={formikProps.values.useremail}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            margin="normal"
                                            {...useremailProps}
                                            disabled={from_emailverify}
                                        />
                                        <ErrorMessage name="useremail">
                                            {(msg) => (<TextMsg severity="error" message={msg} />)}
                                        </ErrorMessage>
                                        {this.renderCustomFields(formikProps)}
                                        <TextField
                                            id="display_name"
                                            type="text"
                                            name="display_name"
                                            label="Come vuoi essere chiamato?"
                                            error={formikProps.errors.display_name && formikProps.touched.display_name ? true : false}
                                            value={formikProps.values.display_name}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            margin="normal"
                                            {...displaynameProps}
                                        />
                                        <ErrorMessage name="display_name">
                                            {(msg) => (<TextMsg severity="error" message={msg} />)}
                                        </ErrorMessage>
                                        <TextField
                                            id="username"
                                            type="text"
                                            name="username"
                                            label="Username"
                                            error={formikProps.errors.username && formikProps.touched.username ? true : false}
                                            value={formikProps.values.username}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            margin="normal"
                                            {...usernameProps}
                                        />
                                        <ErrorMessage name="username">
                                            {(msg) => (<TextMsg severity="error" message={msg} />)}
                                        </ErrorMessage>
                                        <TextField
                                            id="password"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            label="Password"
                                            error={formikProps.errors.password && formikProps.touched.password ? true : false}
                                            value={formikProps.values.password}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.handleClickShowPassword}
                                                        edge="end"
                                                        aria-label={showPassword ? 'Nascondi password' : 'Mostra password'}
                                                    >
                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                            {...passwordProps}
                                        />
                                        <ErrorMessage name="password">
                                            {(msg) => (<TextMsg severity="error" message={msg} />)}
                                        </ErrorMessage>
                                        <TextField
                                            id="password-repeat"
                                            type="password"
                                            name="password_repeat"
                                            label="Repeat password"
                                            error={formikProps.errors.password_repeat && formikProps.touched.password_repeat ? true : false}
                                            value={formikProps.values.password_repeat}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            margin="normal"
                                            {...passwordProps}
                                        />
                                        <ErrorMessage name="password_repeat">
                                            {(msg) => (<TextMsg severity="error" message={msg} />)}
                                        </ErrorMessage>
                                        {serviceTerms
                                            ? <Box mt={2}>
                                                <Switch checked={serv_terms} name="serv_terms" value={serv_terms} onChange={(e) => this.handleChange(e)} />
                                                {serviceTermsLabel}
                                            </Box>
                                            : null}
                                    </FormControl>
                                    <br />
                                    <br />
                                    {buttons(formikProps,serv_terms)}
                                </Form>
                            </React.Fragment>
                        }}
                    />
            </React.Fragment>}
            <SnackbarMessage
                open={signup}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterSignUp}
                //onClose={() => (window.location.href = redirectTo)}
            />
        </React.Fragment>
    }
}

export default withRouter(SignUpForm)