import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import { isValidUrl } from '../@react-fefmwk/requests/api'
import ExternalLink from '../@react-fefmwk/links/modules/ExternalLink'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import SignUpForm from '../@react-fefmwk/auth/modules/SignUpForm'

import { verify_podcaster } from '../services/BE'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'

import { FiberManualRecord,ChevronLeft,RssFeed } from '@material-ui/icons'

const loginStyles = {
    cardContent: {
        textAlign:'center'
    },
    loginLogoContainer: {
        width: '100%',
        marginBottom: 5,
        textAlign: 'center',
    },
    loginBtn: {
        width: '100%',
        marginBottom: 5
    },
    linkClean: {
        textDecoration: 'none',
        color: 'gray',
    },
}

class SignUp extends React.Component {
    render() {
        const theme = this.props.theme
        const defaultHomeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : "/"
        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : defaultHomeUrl
        
        const { classes } = this.props
        const inputProps = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            }
        }
        const inputPropsDisplayName = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            },
            /*helperText: <Typography component="span" variant="caption" color="inherit" style={{textAlign:'center',display:'block'}}>
                Sei un podcaster? Indica il tuo esatto nome in Spotify!
            </Typography>*/
        }
        const profile = this.props.route.hasOwnProperty('profile')
            ? this.props.route.profile
            : "author"
        const id_user_group = profile === "podcaster" ? 3 : 2
        const customFields = profile === "podcaster"
        ? [
            {
                name: "feed_rss",
                initialValue: "",
                validate: (values) => {
                    const feed_rss = values.hasOwnProperty('feed_rss') ? values.feed_rss : ''
                    if( !feed_rss )
                        return "Il feed RSS è obbligatorio!"
                    if(!isValidUrl(feed_rss))
                        return "La url del tuo feed RSS non è valida!"

                    return ''
                },
                render: (formikProps) => {
                    const is_valid_feed_rss = formikProps.values.is_valid_feed_rss
                    const feed_rss = formikProps.values.feed_rss
                    return <TextField
                        id="feed_rss"
                        type="text"
                        name="feed_rss"
                        label="Feed RSS"
                        disabled={is_valid_feed_rss > 0 ? true : false}
                        error={formikProps.errors.feed_rss && formikProps.touched.feed_rss ? true : false}
                        value={feed_rss}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        margin="normal"
                        {...inputPropsDisplayName}
                        helperText={<Typography component="span" variant="caption" color="inherit" style={{textAlign:'center',display:'block'}}>
                            Inserisci il tuo feed RSS per proseguire!
                        </Typography>}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    disabled={feed_rss ? false : true}
                                    onClick={async () => {
                                        const verify = await verify_podcaster(formikProps.values)
                                        formikProps.setFieldValue("is_valid_feed_rss",parseInt(verify))
                                    }}
                                    edge="end"
                                    aria-label="Valida il tuo feed RSS"
                                >
                                    <Tooltip title="Valida il tuo feed RSS" placement="right">
                                        <RssFeed />
                                    </Tooltip>
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                }
            },
            {
                name: "is_valid_feed_rss",
                initialValue: null,
                validate: (values) => {
                    const is_valid_feed_rss = values.hasOwnProperty('is_valid_feed_rss') ? values.is_valid_feed_rss : 0
                    if( !is_valid_feed_rss )
                        return "Il feed RSS non è valido!"

                    return ''
                },
                render: (formikProps) => {
                    const is_valid_feed_rss = formikProps.values.is_valid_feed_rss
                    const label = is_valid_feed_rss === null
                        ? "Indica un feed RSS"
                        : (parseInt(is_valid_feed_rss) > 0
                            ? "Feed RSS valido!"
                            : "Feed RSS non valido!")
                    const color = is_valid_feed_rss === null
                        ? ""
                        : (parseInt(is_valid_feed_rss) > 0
                            ? "green"
                            : "red")
                    return <Chip
                        size="small"
                        label={label}
                        avatar={<FiberManualRecord style={{color:color}} />}
                    />
                }
            },
        ]
        : []

        return <React.Fragment>
            <Box component="div" className={classes.loginLogoContainer}>
                <img src="/images/logo-login.png" alt="Login logo" style={{maxWidth:'50%'}} />
            </Box>
            <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                    <SignUpForm redirectTo={redirectTo}
                        useremailProps={inputProps}
                        displaynameProps={inputPropsDisplayName}
                        usernameProps={inputProps}
                        passwordProps={inputProps}
                        msgWelcome={(user) => <React.Fragment>
                            L'indirizzo <i>{user.email}</i> è verificato!<br />
                            Compila il form sotto per creare il tuo account!<br /><br />
                            <b>Importante</b>: la mail nel feed RSS deve coincidere con la mail <i>{user.email}</i>
                        </React.Fragment>}
                        msgAfterSignUp="Account creato! Vai al login per accedere"
                        id_user_group={id_user_group}
                        customFields={customFields}
                        serviceTerms={true}
                        serviceTermsLabel={<span>
                            ho letto i <ExternalLink to="https://meetpods.com">termini di servizio</ExternalLink>
                        </span>}
                        buttons={(formikProps,serv_terms) => {
                            return <React.Fragment>
                                <Button color="primary" variant="contained" className={classes.loginBtn}
                                    type="submit"
                                    disabled={!formikProps.isValid || !serv_terms}
                                >Registrati</Button>
                                <Button variant="text" className={classes.loginBtn}
                                    component={AdapterLink}
                                    to="/"
                                    startIcon={<ChevronLeft fontSize="small" />}
                                >Torna al login</Button>
                            </React.Fragment>}}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    }
}

export default withStyles(loginStyles)(withTheme(SignUp))