import React from 'react'
//import { Redirect } from "react-router-dom"
import { Formik,Form,ErrorMessage } from 'formik'

import { doLogin } from '../services/api'
import { setToken,getToken } from '../services/token'

import TextMsg from '../../messages/modules/TextMsg'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            token: '',
            showPassword: false,
            //redirect: false
        }
        
        this.doLogin = this.doLogin.bind(this)
    }
    componentDidMount() {
        const token = getToken()
        this.setState({
            token: token,
            //redirect: token ? true : false,
        })
    }

    validate( values ) {
        let errors = {}

        const username = values.hasOwnProperty('username') ? values.username : ''
        const password = values.hasOwnProperty('password') ? values.password : ''

        if( !username )
            errors['username'] = 'Invalid username'
        if( !password )
            errors['password'] = 'Invalid password'

        errors = {
            ...errors,
            ...this.validateCustomFields(values)
        }

        //exec hook onValidate
        const onValidate_f = this.props.hasOwnProperty('onValidate')
            ? this.props.onValidate
            : (values,errors) => {return errors}
        errors = onValidate_f(values,errors)

        return errors
    }
    async doLogin(data) {
        const token = await doLogin( data )

        if( token ) {
            setToken( token )
            this.setState({
                token: token,
            })
        }
    }

    /**
     * This method allows developers to add custom fields to the sign up form.
     * All the customField engin is based on customFields prop, that contains all data in order to manage the rendering, validation e init of the custom fields.
     * This is a simple mockup of the prop customFields (it is a list of objects) that must be provided:
     * [
     *  {
     *      name: "feed_rss",
     *      initialValue: "",
     *      validate: (values) => {
     *          const feed_rss = values.hasOwnProperty('feed_rss') ? values.feed_rss : ''
     *          if( !feed_rss )
     *              return "Sei un podcaster? Il tuo feed RSS è obbligatorio!"
     * 
     *          return ''
     *      },
     *      render: (formikProps) => {
     *          return <TextField
     *              id="feed_rss"
     *              type="text"
     *              name="feed_rss"
     *              label="Feed RSS"
     *              value={formikProps.values.feed_rss}
     *              onChange={formikProps.handleChange}
     *              margin="normal"
     *              {...inputPropsDisplayName}
     *              helperText={<Typography component="span" variant="caption" color="inherit" style={{textAlign:'center',display:'block'}}>
     *                  Sei un podcaster? Inserisci il tuo feed RSS
     *              </Typography>}
     *          />
     *      }
     * }
     * ]
     */
    renderCustomFields( formikProps ) {
        const customFields = this.props.hasOwnProperty('customFields') ? this.props.customFields : []
        if(!customFields.length) return null

        return customFields.map((fieldObj) => {
            const field = fieldObj.hasOwnProperty('name') ? fieldObj.name : ''
            if( !field )
                return null

            return fieldObj.hasOwnProperty('render')
                ? <React.Fragment key={field}>
                    {fieldObj.render(formikProps)}
                    <ErrorMessage name={field}>
                        {(msg) => (<TextMsg severity="error" message={msg} />)}
                    </ErrorMessage>
                </React.Fragment>
                : () => null
        })
    }
    validateCustomFields( values ) {
        let errors = {}
        const customFields = this.props.hasOwnProperty('customFields') ? this.props.customFields : []
        if(!customFields.length)
            return errors

        customFields.map((fieldObj) => {
            const field = fieldObj.hasOwnProperty('name') ? fieldObj.name : ''
            if( !field )
                return null

            const error = fieldObj.hasOwnProperty('validate')
                ? fieldObj.validate(values)
                : ''
            if(error)
                errors[field] = error
        })

        return errors
    }
    initCustomFields() {
        let initialValues = {}
        const customFields = this.props.hasOwnProperty('customFields') ? this.props.customFields : []
        if(!customFields.length)
            return initialValues

        customFields.map((fieldObj) => {
            const field = fieldObj.hasOwnProperty('name') ? fieldObj.name : ''
            if( !field )
                return null

            initialValues[field] = fieldObj.hasOwnProperty('initialValue')
                ? fieldObj.initialValue
                : ''
        })

        return initialValues
    }

    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }))
    }

    render() {
        const token = this.state.token
        const showPassword = this.state.showPassword
        //const redirect = this.state.redirect
        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : '/home'
        const msgAfterLogin = this.props.hasOwnProperty('msgAfterLogin')
            ? this.props.msgAfterLogin
            : 'Login correct'

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps) => <Button color="primary" variant="contained"
                disabled={!formikProps.isValid}
                type="submit"
            >Login</Button>
        const usernameProps = this.props.hasOwnProperty('usernameProps') ? this.props.usernameProps : {}
        const passwordProps = this.props.hasOwnProperty('passwordProps') ? this.props.passwordProps : {}

        return <React.Fragment>
            <SnackbarMessage
                open={token ? true : false}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterLogin}
                onClose={() => (window.location.href = redirectTo)}
                //onClose={() => this.setState({redirect: true})}
            />
            {/*redirect ? <Redirect to={redirectTo} /> : null*/}
            <Formik
                initialValues={{
                    ...{username: '',password: ''},
                    ...this.initCustomFields()
                }}
                validate={(values) => {
                    return this.validate(values)
                }}
                onSubmit={(values,actions) => {
                    this.doLogin(values)
                }}
                render={formikProps => {
                    return <Form id="loginForm" onSubmit={formikProps.handleSubmit}>
                        <Box mb={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="username"
                                    type="text"
                                    name="username"
                                    label="Username"
                                    error={formikProps.errors.username && formikProps.touched.username ? true : false}
                                    value={formikProps.values.username}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    margin="normal"
                                    {...usernameProps}
                                />
                                <ErrorMessage name="username">
                                    {(msg) => (<TextMsg severity="error" message={msg} />)}
                                </ErrorMessage>
                                {this.renderCustomFields(formikProps)}
                                <TextField
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    label="Password"
                                    error={formikProps.errors.password && formikProps.touched.password ? true : false}
                                    value={formikProps.values.password}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                onClick={this.handleClickShowPassword}
                                                edge="end"
                                                aria-label={showPassword ? 'Nascondi password' : 'Mostra password'}
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    {...passwordProps}
                                />
                                <ErrorMessage name="password">
                                    {(msg) => (<TextMsg severity="error" message={msg} />)}
                                </ErrorMessage>
                            </FormControl>
                        </Box>
                        {buttons(formikProps)}
                    </Form>
                }}
            />
        </React.Fragment>
    }
}

export default LoginForm