import React from 'react'
import { Formik,Form,ErrorMessage } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import { selectFieldsFromList,getObjPath, hasOwnPath } from '../@react-fefmwk/arrays/api'
import { idFromToken } from '../@react-fefmwk/auth/services/token'
import { entity_create } from '../@react-fefmwk/entity/services/api'

//import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'
import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import TextMsg from '../@react-fefmwk/messages/modules/TextMsg'
import FieldText from '../@react-fefmwk/form/modules/FieldText'
import FieldMultiSelect from '../@react-fefmwk/form/modules/FieldMultiSelect'
import FieldHidden from '../@react-fefmwk/form/modules/FieldHidden'
import FieldRadios from '../@react-fefmwk/form/modules/FieldRadios'
import FieldCheckboxes from '../@react-fefmwk/form/modules/FieldCheckboxes'

import {users_get_survey} from '../services/BE'
import {spotify_search} from '../services/BE'

import { Box, Typography, Button, Grid, Paper } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = theme => {
    return {
        errorMsg: {
            marginLeft: 14,
            color: theme.palette.error.main
        },
    }
}

/*const yupSchema = Yup.object().shape({
    vat_number: Yup.string()
        .min(11, 'Too Short! 11 digits required')
        .max(11, 'Too Long! 11 digits required')
        .required('Required'),
    name: Yup.string()
        .min(4, 'Too Short!')
        .max(200, 'Too Long!')
        .required('Required'),
    lei: Yup.string()
        .min(20, 'Too Short! 20 digits required')
        .max(20, 'Too Long! 20 digits required'),
    cap: Yup.string()
        .min(5, 'Too Short! 5 digits required')
        .max(5, 'Too Long! 5 digits required')
})*/

class Survey extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: 0,
            survey: [],
            form: {},
            goon: false,
            submit: false,
            affected_rows: 0,
        }
    }
    async componentDidMount() {
        const id_user = idFromToken()
        const survey = await users_get_survey(id_user)
        let form = {answers: survey}
        if( survey.length ) {
            form['answers'] = selectFieldsFromList(survey,['id_user','id_survey_question','answer'])
        }
        this.setState({
            id_user: id_user,
            survey: survey,
            form: form
        })
    }

    validate(values) {
        const errors = {}
        let errors_n = 0

        const answers = values.hasOwnProperty('answers') ? values.answers : []
        if( answers.length ) {
            errors['answers'] = []
            answers.map((answerRow,a) => {
                const answer = answerRow.hasOwnProperty('answer') ? answerRow.answer : ''

                //check if value is ok
                let isError = true
                if(typeof answer === 'string')
                    isError = answer ? false : true
                else
                    isError = answer.length ? false : true

                //set error msg
                errors['answers'][a] = isError
                    ? {answer: 'Devi indicare almeno una preferenza'}
                    : {}
                //count errors number
                errors_n = errors_n + (isError ? 1 : 0)
            })
        }

        return errors_n > 0 ? errors : {}
    }
    async submit(values,actions) {
        let submit = false
        let affected_rows = 0
        const data = values.hasOwnProperty('answers') ? values.answers : []
        if( data.length ) {
            submit = await entity_create('surveys_answers',data)
            affected_rows = submit.hasOwnProperty('affected_rows') ? submit.affected_rows : 0
            /*submit = true
            affected_rows = 2*/
        }
        
        this.setState({
            submit: affected_rows > 0 ? true : false,
            affected_rows: affected_rows
        })
    }

    renderQuestion(q,question,formikProps) {
        const id_user = this.state.id_user
        const id_survey_question = question.id_survey_question
        const question_text = question.question
        const input_type = question.input_type
        const helper = question.helper
        const placeholder = question.placeholder
        let items = question.answer_options_items
        const n = q+1
//console.log(question)
        const {classes} = this.props

        const QuestionText = <Typography variant="overline" component={Box} mb={2}>
            {n+". "+question_text}
        </Typography>

        const idUserInput = <React.Fragment>
            <FieldHidden
                name={"answers["+q+"].id_user"}
                value={id_user}
            />
        </React.Fragment>
        const idSurveyQuestionInput = <React.Fragment>
            <FieldHidden
                name={"answers["+q+"].id_survey_question"}
                value={id_survey_question}
            />
        </React.Fragment>

        let QuestionInput = null
        const name = "answers["+q+"].answer"
        const error = getObjPath(formikProps.errors,name) !== undefined && getObjPath(formikProps.touched,name) !== undefined
            ? true
            : false
        const QuestionInputError = <ErrorMessage name={name}> 
            {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
        </ErrorMessage>
        const value = getObjPath(
            formikProps.values,
            name,
            null//if not found
        )
        switch(input_type) {
            case "checkbox":
                QuestionInput = <React.Fragment>
                    {QuestionInputError}
                    <FieldCheckboxes row={true}
                        items={items}
                        name={name}
                        error={error}
                        fieldId="option"
                        fieldName="option"
                        value={String(value)}
                        checks={formikProps.values.answers[q].answer}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helper}
                        formikProps={formikProps}
                    />
                </React.Fragment>
            break
            case "selectbox":
            case "radio":
                QuestionInput = <React.Fragment>
                    {QuestionInputError}
                    <FieldRadios row={true}
                        items={items}
                        name={name}
                        error={error}
                        fieldId="option"
                        fieldName="option"
                        value={String(value)}
                        onChange={(_,val) => {
                            formikProps.setFieldValue(name,val)
                            formikProps.setFieldTouched(name,true,true)
                        }}
                        onBlur={formikProps.handleBlur}
                        helperText={helper}
                    />
                </React.Fragment>
            break
            case "multiselectbox":
                QuestionInput = <React.Fragment>
                    {QuestionInputError}
                    <FieldMultiSelect
                        title=""
                        name={name}
                        error={error}
                        fieldId="option"
                        fieldName="option"
                        items={items}
                        value={value}
                        //valueSelected={value}
                        //onChange={formikProps.handleChange}
                        //onBlur={formikProps.handleBlur}
                        formikProps={formikProps}
                        helperText={helper}
                        maxSelections={5}
                        //inputProps={inputProps}
                    />
                </React.Fragment>
            break
            case "smartmultiselectbox":
                if(value) {
                    items = [
                        ...value.map((i) => {return {option:i}}),
                        ...items
                    ]
                    //console.log(items)
                }
                QuestionInput = <React.Fragment>
                    {QuestionInputError}
                    <FieldMultiSelect
                        title=""
                        name={name}
                        error={error}
                        fieldId="option"
                        fieldName="option"
                        items={items}
                        value={value}
                        //valueSelected={value}
                        //onChange={formikProps.handleChange}
                        //onBlur={formikProps.handleBlur}
                        formikProps={formikProps}
                        helperText={helper}
                        maxSelections={5}
                        //inputProps={inputProps}
                        onInputChange={async (e,newValue) => {
                            if( newValue.length > 3 ) {
                                console.log(value)
                                const res = await spotify_search(newValue)
                                const shows = hasOwnPath(res,'shows.items') ? res.shows.items : []
                                if( shows && shows.length > 0 ) {
                                    let items = shows.map((show) => {
                                        return show.name
                                    })
                                    items = [
                                        ...((Array.isArray(value) && value) ? value : []),
                                        ...((Array.isArray(items) && items) ? items : [])
                                    ]
                                    items = [...new Set(items)]
                                    items = items.map((i) => {
                                        let obj = {}
                                        obj["option"] = i
                                        obj["option"] = i
                                        return obj
                                    })
                                    return items
                                }
                            }
                        }}
                    />
                </React.Fragment>
            break
            default:
                QuestionInput = <React.Fragment>
                    {QuestionInputError}
                    <FieldText
                        title=""
                        name={name}
                        error={error}
                        type="string"
                        value={value}
                        variant="outlined"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helper}
                        placeholder={placeholder}
                    />
                </React.Fragment>
            break
        }

        return <React.Fragment key={id_survey_question}>
            <Box mt={2} mb={4}>
                {QuestionText}
                {idUserInput}
                {idSurveyQuestionInput}
                {QuestionInput}
            </Box>
        </React.Fragment>
    }
    render() {
        const survey = this.state.survey
        const form = this.state.form
        if( !survey.length || !Object.keys(form).length )
            return <LoaderSingleComponent width="100%" />

        const goon = this.state.goon
        const submit = this.state.submit
        const affected_rows = this.state.affected_rows
        const submitOk = submit && affected_rows ? true : false
        const submitError = submit && affected_rows <= 0 ? true : false

        const withBackBtn = this.props.hasOwnProperty('withBackBtn') ? this.props.withBackBtn : false

        return <React.Fragment>
            <Formik
                validateOnMount={true}
                enableReinitialize={true}
                initialValues={form}
                validate={(values) => {
                    return this.validate(values)
                }}
                //validationSchema={entityValidationSchema}
                onSubmit={(values,actions) => {
                    return this.submit( values,actions )
                }}
                render={formikProps => {
                    const isValid = formikProps.isValid
                    return <Form>
                        <Box mb={2}>
                            <Paper><Box p={2}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    Clicca su salva per inviarci le tue preferenze
                                </Grid>
                                <Grid item xs="auto">
                                    <Button variant="contained" color="primary"
                                        type="submit"
                                        disabled={!isValid}
                                    >
                                        salva
                                    </Button>
                                </Grid>
                                {!withBackBtn
                                    ? <Grid item xs="auto">
                                        <Button color="primary"
                                            disabled={!goon}
                                            endIcon={<ChevronRightIcon />}
                                            onClick={() => window.parent.location.href = "/app/pods-wall"}
                                        >
                                            Podswall
                                        </Button>
                                    </Grid>
                                    : null}
                                {withBackBtn
                                    ? <Grid item xs="auto">
                                        <GoBackLink type="button"
                                            variant="text"
                                            color="secondary"
                                            Component={(props) => <Button  {...props}
                                                color="primary"
                                                endIcon={<ChevronRightIcon />}
                                            >
                                                Indietro
                                            </Button>}
                                        />
                                    </Grid>
                                    : null}
                            </Grid>
                            </Box></Paper>
                        </Box>
                        {survey.map((question,q) => {
                            const id_survey_question = question.id_survey_question

                            return <React.Fragment key={id_survey_question}>
                                {this.renderQuestion(q,question,formikProps)}
                            </React.Fragment>
                        })}
                    </Form>
                }}
            />
            <SnackbarMessage
                open={submit}
                message={submitError ? "Preferenze non salvate" : "Preferenze salvate. Grazie"}
                severity={submitError ? "error" : "success"}
                autoHideDuration={2000}
                onClose={() => {
                    this.setState({
                        goon: submit && submitOk && !submitError ? true : false,
                        submit:false,
                        affected_rows:0,
                    })
                }}
            />
        </React.Fragment>
    }
}

export default withStyles(styles)(Survey)