import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from "react-router-dom"
import { queryHashToStr } from '../../links/service/api'

import FullHeightBoxes from '../../theme-structure/modules/pieces/FullHeightBoxes'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

const styles = theme => ({
    tabsHorizontal: {
        borderBottom: "1px solid lightgrey"
    },
    tabsVertical: {
        borderRight: "1px solid lightgrey",

    },
    tabsContainerHorizontal: {
        height: "100%",
    },
    tabsContainerVertical: {
        height: "100%",
        display: "flex",
    },
    tabPanelContainerHorizontal: {
        height: "100%",
        //paddingTop: theme.spacing(2)
    },
    tabPanelContainerVertical: {
        height: "100%",
        //marginTop: theme.spacing(1),
        //paddingLeft: theme.spacing(1),
        flexGrow: 1
    },
    tabWrapper: {
        alignItems: 'self-start',
        justifyContent: 'flex-start',
    }
})

class Tabber extends React.Component {

    constructor(props) {
        super(props)

        this.tabIndexFromSlug = this.tabIndexFromSlug.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.buildTabs = this.buildTabs.bind(this)
        this.buildTabPanels = this.buildTabPanels.bind(this)

        const tabs = this.props.tabs
        const queryHash = queryHashToStr(this.props.location.hash)
        const slugFromHash = queryHash ? queryHash : false
        const activeTab = this.tabIndexFromSlug(tabs,slugFromHash)

        this.state = {
            activeTab: activeTab,
            activeSlug: tabs[activeTab].slug
        }
    }
    componentDidUpdate() {
        const updateUrl = this.props.hasOwnProperty("updateUrl") ? this.props.updateUrl : true
        if( updateUrl ) {
            const tabs = this.props.tabs
            const queryHash = queryHashToStr(this.props.location.hash)
            const slugFromHash = queryHash ? queryHash : false
            const activeTab = this.tabIndexFromSlug(tabs,slugFromHash)

            if( activeTab !== this.state.activeTab ) {
                this.setState({
                    activeTab: activeTab,
                    activeSlug: tabs[activeTab].slug
                })
            }
        }
    }
    tabIndexFromSlug(tabs,slug) {
        let activeTab = false
        tabs.map((tab,index) => {
            if( tab.slug === slug ) activeTab = index
            return true
        })
        return activeTab === false ? 0 : activeTab
    }

    handleChange( e,index ) {
        e.persist()

        const activeTab = index
        const activeSlug = this.props.tabs[index].slug
        this.setState({
            activeTab: activeTab,
            activeSlug: activeSlug
        })

        //update url on tab change
        const updateUrl = this.props.hasOwnProperty("updateUrl") ? this.props.updateUrl : true
        if( updateUrl ) {
            const pathname = this.props.location.pathname
            this.props.history.replace(pathname+"#"+activeSlug)
        }
    }

    buildTabs() {
        const basicname = this.props.basicname
        const tabs = this.props.tabs
        const orientation = this.props.orientation

        return tabs.map((tab,index) => {
            const label = tab.label
            const slug = tab.slug
            const id = basicname+"-tab-"+index
            const ariacontrols = basicname+"-tabpanel-"+index

            const {classes} = this.props
            const tabClasses = orientation === 'vertical'
                ? {wrapper: classes.tabWrapper}
                : {}
            
            return <Tab
                key={id+"-"+slug}
                label={label}
                id={id}
                aria-controls={ariacontrols}
                classes={tabClasses}
            />
        })
    }
    buildTabPanels() {
        const activeTab = this.state.activeTab
        const activeSlug = this.state.activeSlug
        const basicname = this.props.basicname
        const orientation = this.props.orientation
        const tabs = this.props.tabs

        const { classes } = this.props
        const classTabPanelContainer = orientation === "vertical"
            ? classes.tabPanelContainerVertical
            : classes.tabPanelContainerHorizontal

        const updateUrl = this.props.hasOwnProperty("updateUrl") ? this.props.updateUrl : true
        const queryHash = queryHashToStr(this.props.location.hash)
        if( updateUrl && queryHash && queryHash !== activeSlug )
            return null

        const TabPanel = (props) => {
            const { children, value, index, ...other } = props
            return <Box
                role="tabpanel"
                hidden={value !== index}
                id={basicname+"-tabpanel-"+index}
                aria-labelledby={basicname+"-tab-"+index}
                className={classTabPanelContainer}
                {...other}
            >
                {value === index && (children)}
            </Box>
        }

        return tabs.map((tab,index) => {
            const slug = tab.slug
            const ariacontrols = basicname+"-tabpanel-"+index
            const tabcontent = tab.content
            return <TabPanel
                key={ariacontrols+"-"+slug}
                value={activeTab}
                index={index}
            >
                {tabcontent}
            </TabPanel>
        })
    }

    render() {
        const basicname = this.props.basicname
        const orientation = this.props.orientation ? this.props.orientation : "horizontal"
        const activeTab = this.state.activeTab
        const centered = this.props.centered
        const scrollable = this.props.hasOwnProperty('scrollable') ? this.props.scrollable : "scrollable"

        const {classes} = this.props
        const containerClassName = orientation === "vertical"
            ? classes.tabsContainerVertical
            : classes.tabsContainerHorizontal
        const tabsClassName = orientation === "vertical"
            ? classes.tabsVertical
            : classes.tabsHorizontal

        const TabsList = <Tabs value={activeTab} orientation={orientation} className={tabsClassName}
                onChange={this.handleChange}
                indicatorColor="primary"
                aria-label={basicname}
                centered={centered}
                variant={scrollable}
            >
                {this.buildTabs()}
            </Tabs>
        const TabPanelsList = this.buildTabPanels()

        return <React.Fragment>
            <Box className={containerClassName}>
                {orientation === "vertical"
                    ? <React.Fragment>
                        {TabsList}
                        {TabPanelsList}
                    </React.Fragment>
                    : <FullHeightBoxes
                        FixedC={TabsList}
                        ScrollC={TabPanelsList}
                    />}
            </Box>
        </React.Fragment>
    }
}

export default withRouter(withStyles(styles)(Tabber))