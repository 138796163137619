export function getTableState(tableRef) {
    return tableRef.current
        ? tableRef.current.getResolvedState()
        : {}
}
export function cleanResolvedData(resolvedData) {
    if( !resolvedData )
        return []

    return resolvedData.map(({
        _original,
        _index,
        _subRows,
        _nestingLevel,
        ...resto
    }) => resto)
}
export function formatData(value,dataType='string',decimalDigits=2,currency="EUR",exponent=0,localeString='de-DE',emptyValue="-"){

    const exponentialNotationFactor = Math.pow(10, exponent);

    //INTEGER
    if(dataType === "integer"){       
        value =  parseFloat(value).toLocaleString(localeString,{ style:'decimal', minimumFractionDigits:0, maximumFractionDigits:0 })
    }

    //DECIMAL
    else if(dataType === "decimal"){ 
        value = parseFloat(value) / exponentialNotationFactor;
        if(isNaN(value))
            value = emptyValue;
        else
            value = value.toLocaleString(localeString,{ style:'decimal', minimumFractionDigits:decimalDigits, maximumFractionDigits:decimalDigits })
    }

    //PERCENT
    else if(dataType === "percent"){ 
        value = parseFloat(value)
        if(isNaN(value))
            value = emptyValue;
        else
            value = parseFloat(value).toLocaleString(localeString,{ style:'percent', minimumFractionDigits:decimalDigits, maximumFractionDigits:decimalDigits })
    }

    //STRING
    else if(dataType === "string"){
       
    }

    //DATE
    else if(dataType === "date"){
        
    }

    //CURRENCY
    else if(dataType === "currency"){
        value = parseFloat(value) / exponentialNotationFactor;
        if(isNaN(value))
            value = emptyValue;
        else
            value = value.toLocaleString(localeString,{ style:'currency', currency:currency, minimumFractionDigits:decimalDigits, maximumFractionDigits:decimalDigits })
    }
    
    if(!value)
        return emptyValue;

    return value
}
export function JSONToCSV( JSONData,ReportTitle="CSV",ShowLabel=true,separator=";",decimalSeparator="," ) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';
    var row = "";
    //This condition will generate the Label/Header
    if (ShowLabel) {
        row = "";
        
        //This loop will extract the label from 1st index of on array
        for (var j in arrData[0]) {
            
            //Now convert each value to string and dot-comma-separated
            row += j + ';';
        }

        row = row.slice(0, -1);
        
        //append Label row with line break
        CSV += row + '\r\n';
    }
    
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        row = "";
        
        //2nd loop will extract each column and convert it in string dot-comma-separated
        for (var index in arrData[i]) {
            var data = arrData[i][index];
            var adjData = data;
            if (!isNaN(parseFloat(data)) && isFinite(data))
                adjData =  String(adjData).replace(".",decimalSeparator);
            if(!adjData)
                adjData =  "";

            row += '"' + adjData + '"'+ separator;
        }

        row.slice(0, row.length - 1);
        
        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV === '') {        
        alert("Invalid data");
        return;
    }   
    
    //Generate a file name
    var fileName = "";
    var date = new Date();
    var output = String(date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2)+'-'+("0" + date.getDate()).slice(-2)+'_'+date.getHours()+'h'+date.getMinutes()+'m'+date.getSeconds()+"s")
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g,"_") + "_"+output;   
    
    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension    
    
    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");    
    link.href = uri;
    
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export function rowObjectToRows( object ) {
    let rows = []
    Object.entries(object).map((row,index) => rows.push(row))

    return rows
}
export function niceField( field ) {
    field = field.charAt(0).toUpperCase() + field.slice(1)
    return field.replaceAll("_"," ").replaceAll("-"," ")
}