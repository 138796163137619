import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles,withTheme } from '@material-ui/core/styles'

import Header from '../Header'
import NavSide from '../NavSide'
import Footer from '../Footer'

import { currentUrl } from '../../../requests/api'

import '../theme.css'

import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const StandardPageStyle = theme => ({
    app: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: "url('/images/app-background.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        //background: "url('/images/app-background.png') no-repeat",
    },
    appHeaderBar: theme.globals.appHeaderBar,
    appBody: {
        ...theme.globals.appBody,
        ...{
            height: '100%',
            flex: "1 1",
            height: 0,
            display: 'flex',
            //flexWrap: 'wrap',
            flexDirection: 'row',
            boxSizing: 'border-box',
            //flexGrow: '1 1',
            //flexShrink: '1 3',
        },
    },
    rightBody: {
        ...{
            flexGrow: 1,
            flexBasis: 0,
            overflowX: 'auto',
            height: '100%'
        },
        ...theme.globals.rightBody,
    },
    leftBody: {
        ...{
            flexGrow: 0,
            //overflowY: 'auto',
            height: '100%'
        },
        ...theme.globals.leftBody,
    },
    standardFooter: {
        paddingLeft: theme.globals.appNavSideDrawer.width,
        width:'calc(100% - '+theme.globals.appNavSideDrawer.width+'px)',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            width:'100%',
        },
    }
})

class StandardPage extends React.Component {
    constructor() {
        super()

        this.state = {
            openNavSide: false,
        }
    }
    toggleNavSide(e) {
        if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            return
        }
        this.setState({
            openNavSide: !this.state.openNavSide
        })
    }
    handleClickAway() {
        if( this.state.openNavSide )
            this.setState({
                openNavSide: false
            })
    }
    render() {
        const { classes,theme } = this.props
        const currentPath = currentUrl()
        const openNavSide = this.state.openNavSide

        const appStyle = this.props.hasOwnProperty('appStyle')
            ? this.props.appStyle
            : {}

        const NavTopTitle = theme.hasOwnProperty('navtop_title') ? theme.navtop_title : ""
        const NavTopImgPath = theme.hasOwnProperty('navtop_logo') ? theme.navtop_logo : ""
        const ShowUsername = theme.hasOwnProperty('navtop_showusername') ? theme.navtop_showusername : ""
        const showUserDivider = theme.hasOwnProperty('navtop_showuserdivider') ? theme.navtop_showuserdivider : false
        const homeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : false

        return <div id="App" className={classes.app} style={appStyle}>
            <Header className={classes.appHeaderBar}
                showAppLogo={true}
                navTopTitle={NavTopTitle}
                NavTopImgPath={NavTopImgPath}
                showUsername={ShowUsername}
                showUserDivider={showUserDivider}
                homeUrl={homeUrl}
                MenuBtn={<IconButton size="small" color="secondary" onClick={(e) => this.toggleNavSide(e)}>
                    <MenuIcon />
                </IconButton>}
            />
            <div id="AppBody" className={classes.appBody}>
                <div id="Left" className={classes.leftBody}>
                    <Hidden smDown>
                        <NavSide currentPath={currentPath} variant="permanent" open={true} />
                    </Hidden>
                    <Hidden xsDown mdUp>
                        <NavSide currentPath={currentPath} variant="permanent" open={false} />
                    </Hidden>
                    <Hidden smUp>
                        <div onClick={(e) => this.toggleNavSide(e)}>
                            <NavSide currentPath={currentPath} variant="temporary" open={openNavSide} />
                        </div>
                    </Hidden>
                </div>
                <div id="Right" className={classes.rightBody}>
                    {this.props.children}
                </div>
            </div>
            <Footer textAlign="left" itemXs={4} className={classes.standardFooter} />
        </div>
        
    }
}

export default withRouter(withStyles(StandardPageStyle)(withTheme(StandardPage)))