import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import Footer from '../../@react-fefmwk/theme-structure/modules/Footer'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

const LoginLayoutStyle = theme => ({
    container: {
        //height: "100vh"
    },
    grid: {
        marginTop: "4%"
    },
    welcomeBox: {
        margin: theme.spacing(4),
        textAlign: "center",
        color: "white"
    },
})

class LoginLayout extends React.Component {
    constructor(props) {
        super(props)

        const assets_path = '/images'

        //set background image
        const backgroundImage = assets_path+"/mp-login-background.png"
        let html = document.querySelector('html')
        html.style.backgroundImage = "url('"+backgroundImage+"')"
    }
    render() {
        const { classes } = this.props

        return <React.Fragment>
            <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={4} direction="row" alignItems="flex-start" className={classes.grid}>
                        <Grid item md={4} xs={12}>
                            {/*Col left*/}
                        </Grid>
                        <Grid id="Login" item md={4} xs={12}>
                            {this.props.children}
                        </Grid>
                        <Grid item md={4} xs={12}>
                            {/*Col right*/}
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="md" className={classes.welcomeBox}>
                    <Typography variant="h3" component="h1">People meet Podcasts.</Typography>
                    <Box mt={4}>
                        <Typography variant="body1" component="div">
                        <p>Nuovo qui?<br />
                        Iscriviti ed entra a far parte della community di Meetpods.</p>
                        <p>Con Meetpods puoi scorpire nuovi Podcasts da ascoltare in base ai tuoi gusti, recensire i tuoi Show preferiti, seguire i tuoi Podcaster e interagire con una community di Pocdcast lovers come te.</p>
                        <p>Non sai cosa ascoltare?<br />
                        Dacci qualche suggerimento per aiutarti a trovare il tuo prossimo podcast preferito.</p>
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Footer containerSize="md" textAlign="center" itemXs={4} />
        </React.Fragment>
    }
}

export default withRouter(withStyles(LoginLayoutStyle)(LoginLayout))