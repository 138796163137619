import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
//import InputLabel from '@material-ui/core/InputLabel'
import { Autocomplete } from '@material-ui/lab'
import FormHelperText from '@material-ui/core/FormHelperText'
import { TextField } from "@material-ui/core"

const style = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 200,
        width: '100%'
    },
    labelOnError: {
        color: theme.palette.error[500]
    },
    defaultValue: {
        color:theme.palette.grey[500],
        textTransform:"uppercase",
    }
})

class FieldMultiSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            searchText: "",
            items: this.props.items
        }

        // Timeout ID per gestire il debounce
        this.debounceTimeout = null
    }

    handleSearchChange = (event) => {
        this.setState({
            searchText: event.target.value
        })
    }
    
    render() {
        const name = this.props.name
        const title = this.props.title ? this.props.title : ""
        const id = this.props.id ? this.props.id : ""
        const items = this.state.items ? this.state.items : []
        const formikProps = this.props.hasOwnProperty('formikProps') ? this.props.formikProps : {}
        const error = this.props.error
        let value = this.props.value
        if( value === undefined ) value = formikProps.values[name]
        //const error = Boolean(formikProps.errors[name] && formikProps.touched[name])
        const fieldId = this.props.fieldId
        const fieldName = this.props.fieldName
        const helperText = this.props.helperText
        const variant = this.props.variant ? this.props.variant : "outlined"
        const chipColor = this.props.chipColor ? this.props.chipColor : "secondary"
        const chipSize = this.props.chipSize ? this.props.chipSize : "small"
        const disabled = this.props.disabled
        const maxSelections = this.props.hasOwnProperty('maxSelections') ? this.props.maxSelections : null
        const onInputChange = this.props.hasOwnProperty('onInputChange') ? this.props.onInputChange : () => true

        const { classes } = this.props

        return <FormControl fullWidth variant={variant} className={classes.formControl} error={error}>
            <Autocomplete
                id={id}
                //label={title}
                multiple
                options={items} // Passa l'array di opzioni completo
                getOptionLabel={(option) => option[fieldName]} // Mostra il valore dell'opzione
                filterSelectedOptions
                value={items.filter((option) => value.includes(option[fieldId]))} // Sincronizza con Formik
                disabled={disabled}
                onInputChange={async (e,newInputValue) => {
                    // Cancella il timeout precedente
                    clearTimeout(this.debounceTimeout)

                    // Imposta un nuovo timeout
                    this.debounceTimeout = setTimeout(async () => {
                        const new_items = await onInputChange(e,newInputValue)
                        if( new_items && new_items.length > 0 ) {
                            this.setState({
                                items: new_items
                            })
                        }
                    }, 500) // Ritardo di 500 ms
                }}
                onChange={(_, selectedOptions) => {
                    // Limita il numero massimo di selezioni a 3
                    if (maxSelections !== null && selectedOptions.length > maxSelections) {
                        return // Ignora l'aggiornamento se supera il limite
                    }
                    // Aggiorna Formik con le chiavi delle opzioni selezionate
                    const selectedKeys = selectedOptions.map((option) => option[fieldId])
                    formikProps.setFieldValue(name, selectedKeys)
                }}
                renderInput={(params) => {
                    return <TextField
                        {...params}
                        label={title}
                        variant="outlined"
                        placeholder="Cerca e seleziona..."
                    />
                }}
                renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                        <Chip
                            key={option[fieldId]}
                            label={option[fieldName]}
                            {...getTagProps({ index })}
                            color={chipColor}
                            size={chipSize}
                        />
                    ))
                }
            />
            <FormHelperText component="div">{helperText}</FormHelperText>
        </FormControl>
    }
}

export default withStyles(style)(FieldMultiSelect)