import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Footer from '../Footer'

//import AdapterLink from '../../../links/modules/AdapterLink'
import GoBackLink from '../../../links/modules/GoBackLink'
import CloseWindowLink from '../../../links/modules/CloseWindowLink'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
//import HomeIcon from '@material-ui/icons/Home'

import '../theme.css'

const FullWidthPageStyle = theme => ({
    app: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: "url('/images/app-background.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        //background: "url('/images/app-background.png') no-repeat",
    },
    appBody: {
        ...theme.globals.appBodyNoHeader,
        ...{
            flex: "1 auto",
            height: 0,
            //overflowY:"auto"
        },
    },
    monoBody: {
        //overflowY: 'auto',
        height: '100%'
    },
    closeBtn: {
        position: 'absolute',
        right: '1%',
        top: '1.5%'
    },
    /*homeBtn: {
        position: 'absolute',
        right: '1%',
        top: '1.5%'
    },*/
    backBtn: {
        position: 'absolute',
        right: '1%',
        top: '1.5%'
    },
})

class PageFullWidth extends React.Component {
    render() {
        const { classes } = this.props

        const appStyle = this.props.hasOwnProperty('appStyle')
            ? this.props.appStyle
            : {}

        const BackButton = <GoBackLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props} className={classes.backBtn}><ArrowBackIcon /></IconButton>}
        />

        const CloseButton = <CloseWindowLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props} className={classes.closeBtn}><CloseIcon /></IconButton>}
        />

        /*const HomeButton = <IconButton component={AdapterLink} to="/" className={classes.homeBtn}>
            <HomeIcon color="secondary" />
        </IconButton>*/

        return <div id="App" className={classes.app} style={appStyle}>
            <div id="AppBody" className={classes.appBody}>
                    {/*HomeButton*/}
                    {BackButton}
                    {CloseButton}
                <div id="Mono" className={classes.monoBody}>
                    {this.props.children}
                </div>
            </div>
            <Footer itemXs={4} />
        </div>
    }
}

export default withStyles(FullWidthPageStyle)(PageFullWidth)