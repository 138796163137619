import React from 'react'

import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'

import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import RssFeedIcon from '@material-ui/icons/RssFeed'

class WidgetRssPreview extends React.Component {
    render() {
        const rss = this.props.hasOwnProperty('rss') ? this.props.rss : {}
        if( !Object.keys(rss).length )
            return <Chip label="Nessun feed RSS" />
console.log(rss)
        const rss_url = rss.hasOwnProperty('rss') ? rss.rss : ''
        const image = rss.hasOwnProperty('image') ? rss.image : ''
        const title = rss.hasOwnProperty('title') ? rss.title : ''
        const description = rss.hasOwnProperty('description') ? rss.description : ''
        const owner = rss.hasOwnProperty('owner') ? rss.owner : ''
        const owner_email = rss.hasOwnProperty('owner_email') ? rss.owner_email : ''
        const copyright = rss.hasOwnProperty('copyright') ? rss.copyright : ''
        const categories = rss.hasOwnProperty('categories') ? rss.categories : []

        return <Grid container spacing={2}>
            <Grid item xs={12} md="auto">
                <Box>
                    <img src={image} width="150" style={{borderRadius:25,boxShadow:"4px 4px 2px lightgrey"}} />
                </Box>
            </Grid>
            <Grid item xs>
                <List style={{paddingTop: 0}}>
                    <ListItem style={{paddingTop: 0}}>
                        <ListItemText
                            primary={<Grid container alignItems="center" spacing={2}>
                                <Grid item xs>{title}</Grid>
                                <Grid item xs="auto">
                                    <IconButton component={ExternalLink} to={rss_url} color="secondary" size="small">
                                        <RssFeedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>}
                            secondary={<React.Fragment>
                                {description}
                                <br />
                                <i>{copyright}</i>
                            </React.Fragment>}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={owner} secondary={owner_email} />
                    </ListItem>
                    <ListItem>
                        {categories.map((category,c) => {
                            return <React.Fragment>
                                <Chip key={c} size="small" label={category} color="secondary" />
                                &nbsp;
                            </React.Fragment>
                        })}
                    </ListItem>
                </List>
            </Grid>

        </Grid>
    }
}

export default WidgetRssPreview